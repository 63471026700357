<template>
  <v-container class="mx-auto">
    <v-card-title class="justify-center text-h5">
      Hi There!
    </v-card-title>
    <v-card-subtitle align="center" class="text-subtitle-1 my-2">
      The sale is on 🔥. We are holding the line for you automatically. When
      it's your turn, tap to try again.
      <br />
      <Countdown :seconds="randomWait" :clickFn="clickFn" class="mt-5" />
    </v-card-subtitle>
    <v-card-text align="center" class="mx-0 px-0">
      <v-expand-transition>
        <v-btn
          color="accent"
          class="my-5"
          @click="expand = !expand"
          v-if="showGame"
        >
          <span v-if="!expand">
            Play a Game ?
          </span>
          <span v-else>
            Close
          </span>
        </v-btn>
      </v-expand-transition>
      <v-expand-transition>
        <v-card flat v-show="expand" widht="100%">
          <Game />
        </v-card>
      </v-expand-transition>
    </v-card-text>
  </v-container>
</template>
<script>
import Game from "@/components/game/TikTokToe/Game.vue";
import Countdown from "@/components/game/Countdown.vue";

export default {
  name: "Wait",
  components: { Game, Countdown },
  props: ["clickFn"],
  data() {
    return {
      showGame: false,
      expand: false,
      randomWait: 30,
    };
  },
  created() {
    setTimeout(() => (this.showGame = true), 3000);
    this.randomWait = this.getRandomWait(20, 60);
    // NOTE: when test locally
    // this.randomWait = this.getRandomWait(3, 15);
  },
  methods: {
    getRandomWait(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
  },
};
</script>
