import { mapActions, mapGetters } from "vuex";
import PaymentMethods from "@/payment-methods.json";

import { PayNowOffline } from "@apiobuild/penny";

export const PaymentsMixin = {
  data() {
    return {
      PaymentMethods: PaymentMethods,
    };
  },
  computed: {
    ...mapGetters("cart", [
      "getAmounts",
      "getShipping",
      "getProducts",
      "getOrder",
      "getCheckoutErrorMessage",
    ]),
    ...mapGetters("chopin", [
      "getSubmitOrderAPI",
      "getStore",
      "getDroppableAmounts",
      "getProductLookup",
    ]),
    callbacks() {
      return {
        // NOTE: for debugging, no need for the control, use vue bindings instead
        hideAllAlerts: () => {
          // console.log("hideAllAlerts");
        },
        showAlerts: (reasons) => {
          // TODO: method to be deprecated
          console.log(reasons);
          // if (message) {
          //   this.setCheckoutErrorMessage(message);
          //   return;
          // }
          if (reasons) {
            this.setCheckoutErrorMessage(
              `Submit order failed. Error codes: ${reasons.join(",")}`
            );
          }
        },
        disableAllInputs: () => {
          // console.log("disableAllInputs");
        },
        showRunning: () => {
          // console.log("showRunning");
        },
        hideRunning: () => {
          // console.log("hideRunning");
        },
        disableAllInputsExceptPayments: () => {
          // console.log("disableAllInputsExceptPayments");
        },
        showPaymentNote: () => {
          // console.log("showPaymentNote");
        },
        showPaidNote: () => {
          // console.log("showPaidNote");
        },
        user: () => this.user,
      };
    },
  },
  methods: {
    ...mapActions("cart", [
      "checkoutInProgress",
      "checkoutSuccess",
      "checkoutWait",
      "checkoutFailed",
      "setCheckoutErrorMessage",
    ]),
    ...mapActions("chopin", ["setDroppableAmounts", "resetFetchStoreAction"]),
    getPaymentMethodHandle(method) {
      if (this.getStore.other_payment_methods === undefined) {
        return {};
      }
      const methodFound = this.getStore.other_payment_methods.find(
        (x) => x.name === method
      );
      return methodFound;
    },
    getPaymentMethodConf(method) {
      const methodFound = this.PaymentMethods.find((x) => x.name === method);
      return methodFound;
    },
    getPaymentMethodsByTabName(tab) {
      return this.getStore.other_payment_methods
        .filter((x) => this.getPaymentMethodConf(x.name).tab === tab)
        .map((x) => {
          return {
            value: x,
            conf: this.getPaymentMethodConf(x.name),
          };
        });
    },
    handleCheckoutError(err, errorFn) {
      this.setCheckoutErrorMessage(err);
      if (err.status === 429) {
        this.checkoutWait();
        return;
      }
      this.checkoutFailed();
      this.trackException();
      if (errorFn) {
        errorFn();
      }
    },
    async checkout(
      method,
      promptFn,
      errorFn,
      paymentMethodId,
      paymentIntentId
    ) {
      let ok = false;
      const payment = {
        method: method,
        method_id: paymentMethodId,
        intent_id: paymentIntentId,
      };
      if (promptFn) {
        promptFn();
      }
      this.checkoutInProgress();
      let output = null;
      output = await PayNowOffline(
        this.getProducts,
        this.getAmounts,
        this.getShipping,
        this.getOrder,
        payment,
        this.getSubmitOrderAPI,
        this.callbacks
      );
      if (output.error) {
        this.handleCheckoutError(output.error, errorFn);
        return { output, ok };
      }
      ok = true;
      return { output, ok };
    },
    async doPayNowOffline(method, promptFn, errorFn, paymentMethodId) {
      const resp = await this.checkout(
        method,
        promptFn,
        errorFn,
        paymentMethodId
      );
      if (!resp.ok) {
        return false;
      }

      this.checkoutDone();
      return true;
    },
    checkoutDone() {
      this.trackPurchase();
      // NOTE: copy amounts to droppable store first
      this.setDroppableAmounts(
        JSON.parse(JSON.stringify(this.getAmounts.amounts))
      );
      // NOTE: refresh cart and amounts
      this.checkoutSuccess();
      // NOTE: reset args fetched status
      this.resetFetchStoreAction();
    },
    trackException(e) {
      // TODO: google analytics does not support drilling down exception description
      this.$gtag.event("exception", {
        description: e || this.getCheckoutErrorMessage,
        fatal: false,
      });
    },
    trackPurchase() {
      this.$gtag.event("purchase", {
        shipping: this.getAmounts.amounts.shipping,
        transaction_id: (this.getOrder.number || {}).short,
        tax: this.getAmounts.amounts.tax,
        value: this.getAmounts.amounts.total,
        items: Object.keys(this.getProducts).map((x) => this.getGTagItem(x)),
      });
    },
    // TODO: consolidate duplicated implementation
    getGTagItem(sku) {
      const product = this.getProductLookup[sku];
      const quantity = this.getProducts[sku].order_qty;
      return {
        id: product.name,
        name: product.nickname,
        category: product.category,
        price: product.price,
        quantity: quantity,
      };
    },
    getAmountsFromMemoryOrVuex() {
      if (this.getAmounts.amounts) {
        return this.getAmounts.amounts;
      } else if (this.getDroppableAmounts !== null) {
        return this.getDroppableAmounts;
      }
      return {};
    },
  },
};
