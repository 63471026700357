<template>
  <v-container
    fluid
    fill-height
    class="py-0"
    style="background-image: linear-gradient(#FC5C7D, #6A82FB)"
  >
    <v-container>
      <v-row
        class="mx-auto px-auto"
        justify="center"
        align="center"
        v-on:wheel.up="handleScroll"
        fill-height
      >
        <v-carousel
          v-model="slideNum"
          class="intro-carousel"
          :continuous="false"
          cycle
          show-arrows
          vertical
          hide-delimiters
          hide-delimiter-background
          height="100%"
          touchless
          interval="15000"
          v-touch="{
            left: () => swipe('Left'),
            right: () => swipe('Right'),
            up: () => swipe('Up'),
            down: () => swipe('Down'),
          }"
        >
          <template v-slot:prev="{ on, attrs }">
            <v-btn icon class="previous-button" v-bind="attrs" v-on="on">
              <v-icon size="50" class="arrow up">mdi-chevron-double-up</v-icon>
            </v-btn>
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-btn icon class="next-button" v-bind="attrs" v-on="on">
              <v-icon size="50" class="arrow down"
                >mdi-chevron-double-down</v-icon
              >
            </v-btn>
          </template>
          <v-carousel-item>
            <v-card
              elevation="0"
              class="white--text"
              style="background: transparent"
            >
              <v-row justify="center" align="end">
                <v-col class="text-center">
                  <span
                    class="text-center text-h1 custom-font poppings d-block pb-5"
                  >
                    Chopin
                  </span>
                  <span class="text-h4"> by apio</span>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col class="text-center px-5">
                  <span class="d-block pb-5">
                    The most beautiful online store mankind could create in 30
                    seconds.
                  </span>
                  <span>
                    With the tools you've already known: Google Sheets and
                    Gmail.
                  </span>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col class="text-right" cols="6" sm="4">
                  <a
                    href="https://chopin.apiobuild.com/demo-store"
                    target="_blank"
                    style="text-decoration: none;"
                  >
                    <v-btn
                      color="secondary"
                      light
                      x-large
                      width="100%"
                      style="max-width: 200px"
                    >
                      Demo Store
                    </v-btn>
                  </a>
                </v-col>
                <v-col class="text-left" cols="6" sm="4">
                  <a
                    href="https://telescope.apiobuild.com/flow/chopin-stores"
                    target="_blank"
                    style="text-decoration: none;"
                  >
                    <v-btn
                      color="primary"
                      outlined
                      x-large
                      width="100%"
                      class="btn-try-glow"
                      style="max-width: 200px"
                    >
                      Try Now
                    </v-btn>
                  </a>
                </v-col>
              </v-row>
            </v-card>
          </v-carousel-item>
          <v-carousel-item>
            <v-card
              elevation="0"
              class="white--text"
              style="background: transparent"
            >
              <v-row justify="center" align="center" class="text-center">
                <v-col>
                  <h1>
                    Why Chopin ?
                  </h1>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-row
                  justify="center"
                  align="center"
                  style="width: 100%"
                  v-for="(w, i) in whyUs"
                  :key="i"
                >
                  <v-col cols="2" sm="1" class="pl-8 text-center">
                    <v-icon size="30" color="white">{{ w.icon }}</v-icon>
                  </v-col>
                  <v-col cols="10" sm="4" class="px-5 text-left text-h6">
                    {{ w.text }}
                  </v-col>
                </v-row>
              </v-row>
              <v-row justify="center" align="center" class="h-20 text-center">
                <v-col>
                  <span class="text-subtitle-2 d-block pb-3">
                    Still not sure?
                  </span>
                  <a
                    href="http://m.me/apiobuild"
                    target="_blank"
                    style="text-decoration: none;"
                  >
                    <v-btn large light>
                      Talk to Us now
                    </v-btn>
                  </a>
                </v-col>
              </v-row>
            </v-card>
          </v-carousel-item>
          <v-carousel-item>
            <v-container>
              <v-row>
                <v-card
                  elevation="0"
                  class="white--text"
                  style="background: transparent"
                >
                  <v-container>
                    <v-row justify="center" align="end" class="text-center">
                      <v-col>
                        <h1>
                          How does Chopin work?
                        </h1>
                      </v-col>
                    </v-row>

                    <v-row justify="center" align="center" class="text-center">
                      <v-col
                        cols="12"
                        sm="3"
                        class="text-center px-3 px-sm-5 py-0"
                      >
                        <span class="d-block text-sm-h6">
                          Add product in Catalog, the product will show on the
                          store app right away.
                        </span>
                      </v-col>
                      <v-col cols="12" sm="8" class="text-left py-0 px-5">
                        <v-img
                          contain
                          :src="require('@/assets/intro/intro-1.png')"
                          max-width="100%"
                          min-height="80"
                          max-height="300"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        class="text-center px-3 px-sm-5 py-0"
                      >
                        <span class="d-block text-sm-h6">
                          You will receive notification on new orders and you
                          see all your orders on Order Google Sheet.
                        </span>
                      </v-col>
                      <v-col cols="12" sm="8" class="text-left py-0 px-5">
                        <v-img
                          contain
                          :src="require('@/assets/intro/intro-2.png')"
                          max-width="100%"
                          min-height="80"
                          max-height="300"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-row>
            </v-container>
          </v-carousel-item>
        </v-carousel>
      </v-row>
    </v-container>
  </v-container>
</template>

<style lang="scss">
.intro-carousel .v-carousel__controls {
  right: 0px !important;
}
.intro-carousel .v-window__next .next-button {
  bottom: 75px;
  left: 0;
  right: 0;
  margin: auto;
  position: fixed;
  display: inline-block;
}

.intro-carousel .v-window__prev .previous-button {
  top: 85px;
  left: 0;
  right: 0;
  margin: auto;
  position: fixed;
  display: inline-block;
}

@-webkit-keyframes uparrow {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateY(-0.4em);
    opacity: 0.9;
  }
}
@-webkit-keyframes downarrow {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateY(0.4em);
    opacity: 0.9;
  }
}

.up {
  -webkit-animation: uparrow 0.6s infinite alternate ease-in-out;
}
.down {
  -webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
}

.btn-try-glow {
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.8)
  );
  background-size: 600% 600%;

  -webkit-animation: AnimationName 5s ease infinite;
  -moz-animation: AnimationName 5s ease infinite;
  animation: AnimationName 5s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>

<script>
export default {
  name: "BrowseStores",
  data() {
    return {
      deltaY: 10,
      slideNum: 0,
      slides: 3,
      whyUs: [
        {
          color: "yellow",
          icon: "fas fa-bolt",
          text:
            "Create beatiful online store fast and easy. No need to learn new tools or design.",
        },
        {
          color: "#00CED1",
          icon: "fab fa-google",
          text:
            "Manage orders in Google Sheets. Automated email confirmation from Gmail.",
        },
        {
          color: "#FFB6C1",
          icon: "fas fa-piggy-bank",
          text:
            "It's free forever. We offer premium features with just $5 a month.",
        },
      ],
    };
  },
  computed: {
    env() {
      return process.env.NODE_ENV;
    },
  },
  methods: {
    swipe(direction) {
      if (direction == "Down") {
        this.slideNum = Math.max(this.slideNum - 1, 0);
      }
      if (direction == "Up") {
        this.slideNum = Math.min(this.slideNum + 1, this.slides - 1);
      }
    },
    handleScroll(e) {
      if (e.deltaY > this.deltaY) {
        this.slideNum = Math.min(this.slideNum + 1, this.slides - 1);
      }
      if (e.deltaY < -this.deltaY) {
        this.slideNum = Math.max(this.slideNum - 1, 0);
      }
    },
  },
};
</script>
