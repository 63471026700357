<template>
  <v-container fluid class="full-screen">
    <router-view />
    <router-view name="custom-domain-store" />
  </v-container>
</template>

<style lang="scss" scoped>
.full-screen {
  height: 100%;
  padding: 0 !important;
}
</style>

<script>
export default {
  name: "StoreHome",
};
</script>
