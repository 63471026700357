<template>
  <v-container>
    <v-alert
      dismissible
      light
      color="#ffcccb"
      border="left"
      class="error-alert"
      v-model="showAlert"
    >
      <v-container>
        <span class="text-h5 d-block pb-5">
          <v-icon size="40">far fa-sad-tear</v-icon>
          oh snap!
        </span>

        <span class="d-block"> {{ getCheckoutErrorMessage }} </span>
        <span>
          Please try again or contact our most awesome developer at
          <a href="mailto:apiobuild@gmail.com">apiobuild@gmail.com</a>.
        </span>
      </v-container>
    </v-alert>

    <v-col class="text-h5 text-center text-uppercase custom-font poppings">
      Checkout
    </v-col>

    <v-container class="px-0">
      <v-stepper v-model="e6" vertical class="elevation-0">
        <template v-for="(step, i) in steps">
          <v-stepper-step
            :key="`${i}-step`"
            :complete="e6 > i"
            :step="i"
            class="px-0 text-h6 text-capitalize"
          >
            {{ i + 1 }}. {{ step.display || step.name }}
          </v-stepper-step>

          <v-stepper-content :key="`${i}-content`" :step="i" class="mx-0 px-0 ">
            <v-container class="px-0">
              <template v-if="step.name === 'review'">
                <CartItemsList :enableLink="false" />
                <v-col v-if="isXs" class="px-1">
                  <OrderSummary />
                </v-col>
              </template>

              <template v-if="step.name === 'basic info'">
                <v-form v-model="step.valid" ref="basic-info">
                  <ContactForm :contact="contact" />
                </v-form>
              </template>

              <template v-if="step.name === 'address'">
                <v-form v-model="step.valid" ref="address">
                  <ShippingForm :payload="shipping" />
                </v-form>
                <v-col v-if="isXs" class="px-1">
                  <OrderSummary />
                </v-col>
              </template>

              <template v-if="step.name === 'payment'">
                <v-col v-if="isXs" class="px-1">
                  <OrderSummary />
                </v-col>
                <SelectPayment
                  :user="getUser()"
                  :promptFn="promptFn"
                  :errorFn="errorFn"
                  :showAlertOnlyErrorFn="showAlertOnlyErrorFn"
                />
              </template>

              <template v-if="step.name === 'confirmation'">
                <OrderConfirmationNote :tryAgainClickFn="goBackFn" />
                <v-col v-if="isXs" class="px-1">
                  <OrderSummary />
                </v-col>
              </template>

              <v-row>
                <v-col cols="6">
                  <v-btn
                    tile
                    block
                    outlined
                    height="50"
                    dark
                    color="black"
                    class="my-10"
                    @click="e6 -= 1"
                    v-if="i > 0 && i < steps.length - 1"
                  >
                    Back
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    tile
                    block
                    height="50"
                    dark
                    color="black"
                    class="my-10"
                    @click="continueStep(step)"
                    v-if="i < steps.length - 1 && step.name !== 'payment'"
                  >
                    Continue
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
        </template>
      </v-stepper>
    </v-container>
  </v-container>
</template>
<style lang="scss">
.order-summary-mobile {
  display: none;
}
@media (max-width: 425px) {
  .order-summary-mobile {
    display: block;
  }
}
.v-stepper__step__step {
  display: none !important;
}
.v-stepper__content {
  border-left: 0px !important;
}
.error-alert {
  position: fixed !important;
  z-index: 1;
  width: 90%;
}
</style>
<script>
import { mapGetters } from "vuex";
import CartItemsList from "@/components/checkout/CartItemsList";
import OrderSummary from "@/components/checkout/OrderSummary";
import ContactForm from "@/components/checkout/ContactForm";
import ShippingForm from "@/components/checkout/ShippingForm";
import SelectPayment from "@/components/checkout/payments/SelectPayment.vue";
import OrderConfirmationNote from "@/components/checkout/OrderConfirmationNote.vue";

export default {
  name: "OrderForm",
  components: {
    CartItemsList,
    OrderSummary,
    ContactForm,
    ShippingForm,
    SelectPayment,
    OrderConfirmationNote,
  },
  data() {
    return {
      e6: 0,
      contact: {},
      shipping: {},
      showAlert: false,
    };
  },
  computed: {
    ...mapGetters("chopin", ["getArgs", "getStore", "showShippingForm"]),
    ...mapGetters("cart", ["getCheckoutStatus", "getCheckoutErrorMessage"]),
    isXs() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    steps() {
      const steps = [
        { name: "review", display: "Review Order" },
        {
          name: "basic info",
          display: "Contact Information",
          valid: false,
          form: "basic-info",
        },
        {
          name: "payment",
          display: "Payments",
        },
        {
          name: "confirmation",
          display: "Order Confirmation",
        },
      ];
      if (this.showShippingForm) {
        steps.splice(2, 0, {
          name: "address",
          display: "Shipping",
          valid: false,
          form: "address",
        });
      }
      return steps;
    },
  },
  methods: {
    promptFn() {
      this.e6 += 1;
      this.showAlert = false;
    },
    errorFn() {
      this.e6 -= 1;
      this.showAlert = true;
    },
    goBackFn() {
      this.e6 -= 1;
      this.showAlert = false;
    },
    showAlertOnlyErrorFn() {
      this.showAlert = true;
    },
    getUser() {
      let address;
      if (this.shipping) {
        address = [
          this.shipping.address1,
          this.shipping.address2,
          this.shipping.city,
          this.shipping.state,
          this.shipping.zipcode,
          this.shipping.country,
        ]
          .filter((x) => x)
          .join("\n");
      }

      let custom = {};
      Object.entries(this.contact).forEach((x) => {
        const k = x[0];
        const v = x[1];
        if (!["name", "phone", "email", "note"].includes(k)) {
          custom[k] = v;
        }
      });

      return {
        name: this.contact.name,
        phone: this.contact.phone,
        email: this.contact.email,
        note: this.contact.note,
        custom: custom,
        address: address,
        zipcode: this.shipping ? this.shipping.zipcode : null,
      };
    },
    continueStep(step) {
      if (step.valid === undefined || step.valid === true) {
        this.e6 += 1;
      } else {
        this.$refs[step.form][0].validate();
      }
    },
  },
};
</script>
