<template>
  <v-text-field
    hide-details
    autofocus
    label="Search"
    prepend-inner-icon="mdi-magnify"
    append-outer-icon="mdi-close"
    v-model="searchBarPayloadMutable.keyword"
    @click:append-outer="$router.push(storeHomeLink)"
    @keydown.esc="$router.push(storeHomeLink)"
    single-line
  ></v-text-field>
</template>

<script>
import { ChopinMixin } from "@/lib/ChopinMixin.js";

export default {
  name: "SearchBar",
  props: ["searchBarPayload"],
  mixins: [ChopinMixin],
  computed: {
    searchBarPayloadMutable() {
      return this.searchBarPayload;
    },
  },
};
</script>
