<template>
  <v-progress-circular
    :rotate="-90"
    :size="200"
    :width="10"
    :value="convertToProgressRange(value)"
    color="secondary"
  >
    <v-col align="center" class="mt-6">
      <template v-if="this.value > 0">
        <span class="text-h2 primary--text d-block"> {{ value }}</span>
        <span class="overline">Seconds</span>
      </template>
      <template v-else>
        <v-btn
          depressed
          class="mb-6 btn-try-again-glow"
          style="border-radius: 100%; border-width: 0"
          height="180"
          width="180"
          color="secondary"
          @click="clickFn"
        >
          <span class="text-h6">
            Try Now
          </span>
        </v-btn>
      </template>
    </v-col>
  </v-progress-circular>
</template>

<style></style>

<script>
export default {
  name: "Countdown",
  props: ["seconds", "clickFn"],
  data() {
    return {
      interval: {},
      value: this.seconds,
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.value === 0) {
        return (this.value = 0);
      }
      this.value -= 1;
    }, 1000);
  },
  methods: {
    convertToProgressRange(s) {
      const output = Math.floor(((this.seconds - s + 1) / this.seconds) * 100);
      return output;
    },
  },
};
</script>
