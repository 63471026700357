<template>
  <v-overlay z-index="5" v-if="product !== null">
    <v-btn fab absolute top right style="z-index: 6">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-card
      ref="overlay"
      v-click-outside="closeOnClickOutside"
      class="product-card-overlay"
      light
      style="z-index: 5; height: 100%"
    >
      <ProductDetail :product="product" v-if="!isMultiOption" />
      <ProductWithOptionsDetail
        :flavorSelect="flavorSelect"
        :product="product"
        @onUpdateMultiOptionToggle="onUpdateMultiOptionToggle"
        v-else
      />
      <ProductStickyAddToCart :product="selected || product" />
    </v-card>
  </v-overlay>
</template>

<style lang="scss">
.v-overlay__content {
  height: 90%;
  width: 90%;
}
</style>

<script>
import { mapGetters } from "vuex";
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";
import ProductDetail from "@/components/product/ProductDetail";
import ProductWithOptionsDetail from "@/components/product/ProductWithOptionsDetail";
import ProductStickyAddToCart from "@/components/product/ProductStickyAddToCart";

export default {
  name: "ProductOverlay",
  data() {
    return {
      selected: null,
      flavorSelect: { open: false },
    };
  },
  components: {
    ProductDetail,
    ProductStickyAddToCart,
    ProductWithOptionsDetail,
  },
  mixins: [ChopinProductMixin],
  methods: {
    closeOnClickOutside() {
      // NOTE: if flavor select is open, do not close overlay, but set open to false
      if (this.flavorSelect.open === true) {
        this.flavorSelect.open = false;
        return;
      }

      // NavigationDuplicated
      this.$router.push(this.$route.path).catch(() => {});
    },
    onUpdateMultiOptionToggle(e) {
      this.selected = e;
    },
  },
  created() {
    if (this.product === null) {
      this.$router.push(this.$route.path).catch(() => {});
    }
  },
  computed: {
    ...mapGetters("chopin", ["getArgs", "getProductLookup"]),
    hashbang() {
      return this.$route.hash ? this.$route.hash.slice(1) : null;
    },
    product() {
      if (this.hashbang === null) {
        return null;
      }
      const product =
        this.getProductLookup[this.hashbang] ||
        this.getArgs["catalog-options"][this.hashbang];
      if (product) {
        return product;
      }
      return null;
    },
    isMultiOption() {
      if (this.product === null) {
        return false;
      }
      return this.getArgs["catalog-options"][this.hashbang] !== undefined;
    },
  },
};
</script>
