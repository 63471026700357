<template>
  <v-app class="full-screen" id="app">
    <v-app-bar
      extension-height="100vh"
      elevate-on-scroll
      fixed
      v-if="argsFetched || isInCheckoutHome"
    >
      <v-app-bar-nav-icon
        @click.stop="categoryNavDrawer = !categoryNavDrawer"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <router-link
        :to="storeHomeLink"
        style="text-decoration: none"
        class="black--text"
      >
        <v-app-bar-title>
          {{ getArgs.store.name }}
        </v-app-bar-title>
      </router-link>
      <v-spacer></v-spacer>
      <v-btn :to="searchLink" icon small elevation="0" class="mx-1">
        <v-icon color="black"> mdi-magnify </v-icon>
      </v-btn>
      <template v-if="getStore.announcement">
        <v-menu
          :value="showAnnouncement"
          close-on-click
          close-on-content-click
          nudge-left="250px"
          nudge-bottom="30px"
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-badge dot overlap color="red">
              <v-btn
                icon
                small
                elevation="0"
                class="mx-1"
                v-bind="attrs"
                v-on="on"
                @click="showAndCloseAnnouncement()"
              >
                <v-icon color="black">mdi-bell-alert-outline</v-icon>
              </v-btn>
            </v-badge>
          </template>

          <v-alert
            icon="mdi-bullhorn-outline"
            border="left"
            color="light"
            class="mb-0"
          >
            {{ getStore.announcement }}
          </v-alert>
        </v-menu>
      </template>

      <v-badge
        overlap
        color="green"
        :value="getTotalQuantity ? getTotalQuantity > 0 : false"
        :content="getTotalQuantity"
        v-if="!isInCheckoutHome"
      >
        <v-btn
          icon
          small
          elevation="0"
          @click.stop="cartDrawer = !cartDrawer"
          class="mx-1"
        >
          <v-icon color="black">mdi-shopping-outline</v-icon>
        </v-btn>
      </v-badge>
    </v-app-bar>

    <v-app-bar elevate-on-scroll absolute v-else>
      <a href="https://apiobuild.com" target="_blank">
        <v-avatar>
          <v-img
            src="https://apiobuild.com/images/logo.png"
            max-height="50"
            contain
          />
        </v-avatar>
      </a>
    </v-app-bar>

    <v-navigation-drawer
      v-model="categoryNavDrawer"
      width="300px"
      fixed
      left
      temporary
      v-if="argsFetched"
    >
      <CategoryNav
        :name="getArgs.store.name"
        :pages="getArgs.store.pages"
        :logo="getArgs.store['logo_url']"
        :categories="categories"
        :color="brandColor"
        :socials="getArgs.store.owner_info"
      />
    </v-navigation-drawer>
    <v-navigation-drawer v-model="cartDrawer" width="90%" fixed right temporary>
      <v-row class="pt-6" align="center">
        <v-col cols="9">
          <span class="text-h5 px-4"> Shopping Cart </span>
        </v-col>
        <v-col cols="3 text-right">
          <v-btn
            @click="cartDrawer = false"
            elevation="0"
            small
            fab
            color="white"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <CartDrawer />
    </v-navigation-drawer>

    <router-view style="height: 100%" />
    <v-footer color="black lighten-1" fixed padless>
      <v-row justify="center" no-gutters>
        <v-col class="py-2 text-center white--text" cols="12">
          Made with
          <a href="https://apiobuild.com" target="_blank">
            <v-avatar size="35" class="mx-3">
              <v-img
                src="https://apiobuild.com/images/logo_dark_sq.svg"
                contain
              />
            </v-avatar>
          </a>
          , ❤️ and 🍵 in 🗽
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Lexend+Zetta&family=Poppins&display=swap");
.custom-font.poppings {
  font-family: "Poppins", sans-serif !important;
}
.custom-font.lexend-zetta {
  font-family: "Lexend Zetta", sans-serif !important;
}
.custom-font.abel {
  font-family: "Abel", sans-serif !important;
}

html,
body,
.container.full-screen {
  height: 100%; /*both html and body*/
  padding: 0px;
}
body {
  margin: 0; /*reset default margin*/
}

.v-tooltip__content.v-tooltip__content--fixed {
  left: 5px;
  right: 10px;
}

.v-app-bar-title__content {
  text-overflow: unset !important;
}

.v-toolbar__extension {
  padding: 0px !important;
}
.v-navigation-drawer {
  z-index: 999 !important;
  display: list-item;
}
</style>

<script>
import { mapGetters } from "vuex";
import { ChopinMixin } from "@/lib/ChopinMixin.js";
import CategoryNav from "@/components/category/CategoryNav";
import CartDrawer from "@/components/checkout/CartDrawer";

export default {
  name: "App",
  components: { CategoryNav, CartDrawer },
  mixins: [ChopinMixin],
  data() {
    return {
      showAnnouncement: true,
      seenAnnouncement: false,
      categoryNavDrawer: false,
      cartDrawer: false,
    };
  },
  updated() {
    this.showAndCloseAnnouncement();
  },
  computed: {
    ...mapGetters("chopin", ["getStore"]),
    ...mapGetters("cart", ["getTotalQuantity"]),
    isInCheckoutHome() {
      return this.$route.name === "checkout";
    },
  },
  methods: {
    async showAndCloseAnnouncement(ms) {
      setTimeout(() => (this.showAnnouncement = false), ms || 5000);
    },
  },
};
</script>
