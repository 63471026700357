<template>
  <v-container fluid class="full-screen" v-if="argsFetched">
    <Hero
      :height="getStore['hero-height']"
      :color="brandColor"
      :images="getStore['background_image_urls']"
      :logo="getStore.hide_logo === true ? null : getStore['logo_url']"
      :name="getStore.name"
      :description="getStore.description"
      :lightBg="getStore.no_description_whiteout"
    />
    <CategoryCollage
      :catalogByCategory="getArgs['catalog-by-category']"
      :n="n"
      :layout="getStore.category_bar_layout"
      v-if="getStore.category_bar_layout !== 'hide'"
    />
    <v-divider class="py-5" />
    <v-container>
      <v-row class="mx-3 font-weight-bold">
        {{ numProducts }} Products
        <router-link class="pl-1" :to="showAllLink"> (Show All) </router-link>
      </v-row>
    </v-container>
    <Products
      v-for="(p, k) in slicedCatalogByCategory()"
      :key="k"
      :categoryName="p[0]"
      :products="p[1]"
      :baseUrl="storeHomeLink"
      :end="slicedCatalogByCategory().length - 1 === k"
      n="8"
    />
    <ProductOverlay />
  </v-container>
  <v-container fluid class="full-screen" v-else>
    <Home />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { ChopinMixin } from "@/lib/ChopinMixin.js";

import Hero from "@/components/hero/Hero";
import CategoryCollage from "@/components/category/CategoryCollage";
import ProductOverlay from "@/components/product/ProductOverlay";
import Products from "@/components/product/Products";
import Home from "@/views/Home";

export default {
  name: "StoreMain",
  data() {
    return {
      n: 5,
    };
  },
  components: { Hero, CategoryCollage, ProductOverlay, Products, Home },
  mixins: [ChopinMixin],
  computed: {
    ...mapGetters("chopin", ["getArgs", "getStore", "argsFetched"]),
  },
  methods: {
    slicedCatalogByCategory() {
      return Object.entries(this.getArgs["catalog-by-category"]).slice(
        0,
        this.n
      );
    },
  },
};
</script>
