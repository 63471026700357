import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, {
  retries: 3,
  retryDelay: () => 1 * 1000,
  retryCondition: (error) => {
    if (error.response) {
      return [404, 503, 504].includes(error.response.status);
    }
    return true;
  },
});

const FlexSearch = require("flexsearch");

const state = () => ({
  Args: {},
  ArgsFetched: null,
  FetchError: null,
  DroppableAmounts: null,
  FlexSearchIndex: null,
});
const getters = {
  getDroppableAmounts(state) {
    return state.DroppableAmounts;
  },
  hasShippingOptions(state, getters) {
    return Object.keys(getters.getShippingOptions).length > 0;
  },
  getAddressInputField(state, getters) {
    // return getters.getStore.required_fields && getters.required_fields === true;
    return getters.getStore.user_inputs.filter((x) => x.name === "address")[0];
  },
  showShippingForm(state, getters) {
    return (
      getters.hasShippingOptions ||
      getters.getAddressInputField.required === true
    );
  },
  getSubmitOrderAPI: (state, getters) => {
    return {
      url: `${getters.getArgs.url}/submit`,
      user_id: getters.getArgs["user-id"],
      token: getters.getStore.chopout_submit_request_auth_token,
    };
  },
  getFetchError: (state) => {
    return state.FetchError;
  },
  getArgs: (state) => {
    return state.Args;
  },
  getStore: (state) => {
    if (!state.Args) {
      return {};
    }
    if (!state.Args.store) {
      return {};
    }
    return state.Args.store;
  },
  argsFetched: (state) => {
    return state.ArgsFetched;
  },
  getShippingOptions: (state) => {
    if (!state.Args) {
      return [];
    }
    if (!state.Args.store) {
      return [];
    }
    const output = [];
    const options = state.Args.store.shipping;
    if (!options) {
      return output;
    }
    Object.entries(options).forEach(([optionName, optionCost]) => {
      output.push({
        optionName,
        optionCost,
      });
    });
    return output;
  },
  getZipCodes: (state) => {
    if (!state.Args) {
      return [];
    }
    if (!state.Args.store) {
      return [];
    }

    let zipcodes = [];
    const areas = state.Args.store.shipping_areas;
    if (!areas) {
      return zipcodes;
    }
    Object.values(areas).forEach((x) => {
      zipcodes = zipcodes.concat(x);
    });
    return zipcodes;
  },
  getProductLookup: (state) => {
    if (!state.Args.catalog) {
      return {};
    }
    const lookup = {};
    state.Args.catalog.forEach((k) => {
      lookup[k.name] = k;
    });
    return lookup;
  },
  getSearchIndex: (state) => {
    return state.FlexSearchIndex;
  },
};
const actions = {
  async fetchStoreArgs({ commit }, payload) {
    let url = `${process.env.VUE_APP_CHOPIN_BACKEND_API}`;
    url = url.replace("<id>", payload.storeId);
    const params = {
      headers: {
        "user-id": payload.userId,
        Authorization: `Bearer ${payload.ttoken}`,
      },
      data: {},
    };
    try {
      commit("updateFetched", false);
      let result = await axios.get(url, params);
      commit("updateArgs", { args: result.data.store_args });
      commit("updateFetched", true);
    } catch (err) {
      commit("updateFetchError", err);
      throw new Error(`Error fetching stores, error: ${err}`);
    }
  },
  setDroppableAmounts({ commit }, payload) {
    commit("setDroppableAmounts", payload);
  },
  resetFetchStoreAction({ commit }) {
    commit("updateFetched", null);
    commit("updateFetchError", null);
  },
  initFlexSearchIndex({ commit }, payload) {
    commit("updateFlexSearchIndex", payload);
  },
};
const mutations = {
  updateArgs(state, payload) {
    state.Args = payload.args;
  },
  updateFetched(state, fetched) {
    state.ArgsFetched = fetched;
  },
  setDroppableAmounts(state, payload) {
    state.DroppableAmounts = payload;
  },
  updateFetchError(state, error) {
    state.FetchError = error;
  },
  updateFlexSearchIndex(state, payload) {
    const index = new FlexSearch({
      // NOTE: CJK support
      // tokenize: (str) => {
      //   /* eslint-disable-next-line no-control-regex */
      //   return str.replace(/[\x00-\x7F]/g, "").split("");
      // },
      tokenize: "full",
      depth: 3,
      limit: 10,
      doc: {
        id: "name",
        field: ["description", "name", "nickname", "category"],
      },
    });
    if (payload) {
      index.add(
        payload.map((x, i) => {
          x["id"] = i;
          return x;
        })
      );
    }
    state.FlexSearchIndex = index;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
