<template>
  <v-container fluid v-if="argsFetched" class="full-screen">
    <v-container class="pt-15 pb-0">
      <v-row class="mx-3 font-weight-bold">
        <v-col cols="12" class="px-0 mx-0 mb-0">
          <v-breadcrumbs
            :items="items"
            class="px-0 pb-0 mx-0 mb-0 text-caption"
          >
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
    </v-container>
    <Products
      v-for="(p, k, i) in filteredCatalogByCategory"
      :key="i"
      :baseUrl="categoryHomeLink(k)"
      :categoryName="getCategoryName(category)"
      :products="p"
      :end="true"
      :showPage="true"
    />
    <ProductOverlay />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";
import Products from "@/components/product/Products";
import ProductOverlay from "@/components/product/ProductOverlay";

export default {
  name: "CategoryHome",
  data() {
    return {
      n: 5,
    };
  },
  components: { Products, ProductOverlay },
  mixins: [ChopinProductMixin],
  updated() {
    const checkIfCategoryExists = () => {
      if (
        this.argsFetched &&
        !(this.category in this.getArgs["catalog-by-category"])
      ) {
        this.$router.push(this.storeHomeLink);
      }
    };
    checkIfCategoryExists();
  },
  computed: {
    ...mapGetters("chopin", ["getArgs", "getStore", "argsFetched"]),
    items() {
      return [
        {
          text: "Home",
          exact: true,
          to: this.storeHomeLink,
          // href: "#"
        },
        {
          text: this.getCategoryName(this.category),
        },
      ];
    },
    filteredCatalogByCategory() {
      const filtered = Object.entries(this.getArgs["catalog-by-category"])
        .filter((pair) => {
          const category = pair[0];
          return category.toLowerCase() === this.category.toLowerCase();
        })
        .map(([category, catalog]) => {
          const out = {};
          out[category] = catalog;
          return out;
        });
      return filtered.length > 0 ? filtered[0] : {};
    },
  },
};
</script>
