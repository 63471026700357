<template>
  <v-container fluid>
    <a
      :href="product.product_url"
      target="_blank"
      style="text-decoration: none"
    >
      <v-card flat @click.native="trackClickAd(product.name)">
        <v-card-text class="text-left font-weight-bold black--text">
          <v-img :src="product.image_url" height="150" contain />
          <v-row>
            <v-col cols="10" class="text-truncate px-0">
              {{ product.name }}
              <br />
              Shop on
              <!-- NOTE: get rid of thie -->
              <span v-if="product.advertiser_name">
                {{ product.advertiser_name }}
              </span>
              <span v-else>
                Amazon
              </span>
              (Ad)
            </v-col>
            <v-col cols="2" class="px-0">
              <v-btn icon color="black">
                <v-icon>mdi-cart-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </a>
  </v-container>
</template>

<script>
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";

export default {
  name: "AdCard",
  props: ["product"],
  mixins: [ChopinProductMixin],
};
</script>
