import Vue from "vue";
import VueRouter from "vue-router";
import StoreHome from "@/views/StoreHome.vue";
import StoreMain from "@/views/StoreMain.vue";
import PageMain from "@/views/PageMain.vue";
import SearchView from "@/views/SearchView.vue";
import CategoryCollageHome from "@/views/CategoryCollageHome.vue";
import ShowAllHome from "@/views/ShowAllHome.vue";
import CategoryHome from "@/views/CategoryHome.vue";
import CheckoutHome from "@/views/CheckoutHome.vue";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const managedDomainRoutes = [
  {
    name: "intro",
    path: "/",
    component: Home,
  },
  {
    path: "/:user/:store",
    component: StoreHome,
    children: [
      {
        path: "",
        name: "store",
        component: StoreMain,
      },
      {
        path: "page/:pageName?",
        name: "page",
        component: PageMain,
      },
      {
        path: "search",
        name: "search",
        component: SearchView,
      },
      {
        path: "category",
        name: "categories",
        component: CategoryCollageHome,
      },
      {
        path: "category/:category",
        name: "category",
        component: CategoryHome,
      },
      {
        path: "browse/all",
        name: "browse",
        component: ShowAllHome,
      },
      {
        path: "",
        component: CheckoutHome,
        name: "checkout",
      },
    ],
  },
  {
    path: "/:alias",
    component: StoreHome,
    children: [
      {
        path: "",
        name: "alias-store",
        component: StoreMain,
      },
      {
        path: "page/:pageName?",
        props: true,
        name: "alias-page",
        component: PageMain,
      },
      {
        path: "search",
        name: "alias-search",
        component: SearchView,
      },
      {
        path: "category",
        name: "alias-categories",
        component: CategoryCollageHome,
      },
      {
        path: "category/:category",
        name: "alias-category",
        component: CategoryHome,
      },
      {
        path: "browse/all",
        name: "alias-browse",
        component: ShowAllHome,
      },
      {
        path: "",
        name: "alias-checkout",
        component: CheckoutHome,
      },
    ],
  },
];

const customDomainRoutes = [
  {
    name: "intro",
    path: "/chopin",
    component: Home,
  },
  {
    path: "*",
    redirect: "/",
    component: StoreMain,
  },
  {
    path: "",
    name: "custom-domain-store",
    component: StoreMain,
  },
  {
    path: "/page/:pageName?",
    props: true,
    name: "custom-domain-page",
    component: PageMain,
  },
  {
    path: "/search",
    name: "custom-domain-search",
    component: SearchView,
  },
  {
    path: "/category",
    name: "custom-domain-categories",
    component: CategoryCollageHome,
  },
  {
    path: "/category/:category",
    name: "custom-domain-category",
    component: CategoryHome,
  },
  {
    path: "/browse/all",
    name: "custom-domain-browse",
    component: ShowAllHome,
  },
  {
    path: "",
    component: CheckoutHome,
    name: "custom-domain-checkout",
  },
];

let routes = managedDomainRoutes;
if (process.env.VUE_APP_DOMAIN === "custom") {
  routes = customDomainRoutes;
}

const router = new VueRouter({
  mode: "history",
  routes,
  /* eslint-disable no-unused-vars */
  scrollBehavior(to, from, savedPosition) {
    if (
      to.name !== undefined &&
      to.name !== null &&
      (to.name.includes("checkout") ||
        to.name.includes("category") ||
        to.name.includes("search") ||
        to.name.includes("browse"))
    ) {
      return { x: 0, y: 0 };
    }
  },
  /* eslint-enable no-unused-vars */
});

export default router;
