<template>
  <v-container fluid class="px-3 mx-0" style="height: 100%; width: 100%">
    <v-row
      class="py-5 px-0 mx-0 pt-10"
      justify="center"
      align="center"
      style="width: 100%"
    >
      <v-col cols="12" sm="6" class="product-detail">
        <v-carousel
          show-arrows-on-hover
          hide-delimiter-background
          :style="overlayTopLeftContainerStyle"
        >
          <v-carousel-item
            v-for="(src, i) in getOrDefaultProductImageUrls(product)"
            style="height: 100%"
            :key="i"
          >
            <v-img :src="src" contain style="height: 100%" />
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        align-self="start"
        class="px-3 pb-5 py-0"
        style="height: 100%"
      >
        <v-col class="pa-0">
          <p
            class="text-h5 mb-1"
            v-bind:class="{ 'text-truncate': overlayProductNameTruncate }"
          >
            {{ product.nickname }}
          </p>

          <p class="text-title mb-1">
            {{ getFormattedPrice(product.price) }}
          </p>
        </v-col>

        <v-col
          class="pa-0 overflow-y-auto"
          :style="overlayBottomRightContainerStyle"
        >
          <span v-html="getDescriptionHTML(product)"></span>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.v-image.v-responsive.v-carousel__item {
  height: 100% !important;
}
.product-detail .v-carousel__controls__item .v-btn__content {
  color: black;
}
</style>

<script>
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";

export default {
  name: "ProductDetail",
  props: ["product"],
  mixins: [ChopinProductMixin],
};
</script>
