<template>
  <v-container fluid class="px-0 py-0">
    <v-btn v-if="product['option:color']" elevation="0" dense icon>
      <v-sheet
        class="rounded-circle mr-3 my-1"
        :color="product['option:color']"
        height="25"
        width="25"
      ></v-sheet
    ></v-btn>

    <v-btn
      class="mr-3 my-1"
      height="25"
      min-width="25"
      dense
      elevation="0"
      v-if="product['option:size']"
    >
      {{ product["option:size"] }}
    </v-btn>

    <v-btn
      class="mr-3 my-1"
      height="25"
      min-width="25"
      dense
      elevation="0"
      v-if="product['option:flavor']"
    >
      {{ product["option:flavor"] }}
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: "ProductOptionsCompactDisplay",
  props: ["product"],
};
</script>
