<template>
  <v-container fluid class="px-0">
    <v-row>
      <template v-for="(k, i) in getUserInputsExceptAddress">
        <v-col
          cols="12"
          :sm="getSmCols(k.type)"
          class="py-1"
          :key="i"
          v-if="k.hide !== true"
        >
          <template v-if="k.type === 'textarea'">
            <v-textarea
              v-model="contactMutable[k.name]"
              outlined
              :label="k.description || k.name"
              :hint="k.description || k.name"
            ></v-textarea>
          </template>
          <template v-if="k.type.includes('text-field')">
            <v-text-field
              class="text-capitalize"
              v-model="contactMutable[k.name]"
              :label="k.name"
              :rules="getRules(k.name, k.required)"
              :type="textFieldType(k.type)"
            ></v-text-field>
          </template>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ContactForm",
  props: ["contact"],
  data() {
    const requiredRule = (v) => !!v || "This field is required";
    const emailFormatRule = (v) => {
      if (v && v.length > 0) {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || "Email is invalid.";
      }
      return "This field is required";
    };
    return {
      requiredRule,
      rules: {
        email: [emailFormatRule],
      },
    };
  },
  computed: {
    ...mapGetters("chopin", ["getArgs", "getStore"]),
    contactMutable() {
      return this.contact;
    },
    getUserInputsExceptAddress() {
      return this.getStore.user_inputs.filter((x) => x.name !== "address");
    },
  },
  methods: {
    textFieldType(textFieldType) {
      if (textFieldType.includes("number")) {
        return "number";
      }
    },
    getSmCols(fieldType) {
      if (fieldType === "textarea") {
        return "12";
      }
      return "6";
    },
    getRules(fieldName, fieldRequired) {
      return (this.rules[fieldName] || []).concat(
        fieldRequired ? [this.requiredRule] : []
      );
    },
  },
};
</script>
