<template>
  <v-container fluid>
    <v-card flat>
      <v-card-text class="text-left font-weight-bold black--text">
        <router-link
          :to="`#${productName}`"
          @click.native="trackViewProduct(product.name)"
        >
          <v-img :src="getProductImageUrl(product.name)" height="150" contain />
        </router-link>
        <v-row>
          <v-col cols="10" class="text-truncate px-0">
            {{ product.nickname }}
            <br />
            {{ getFormattedPrice(product.price) }}
          </v-col>
          <v-col cols="2" class="px-0">
            <template v-if="isMultiOption">
              <v-btn
                icon
                :to="`#${product['option:name']}`"
                :color="isProductOutOfStock(product.name) ? 'red' : 'black'"
                :disabled="isProductOutOfStock(product.name)"
              >
                <v-icon>fas fa-ellipsis-h</v-icon>
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                icon
                :color="isProductOutOfStock(product.name) ? 'red' : 'black'"
                @click="addToCartFunc(product)"
                :disabled="isProductOutOfStock(product.name)"
              >
                <v-icon>mdi-cart-plus</v-icon>
              </v-btn>
            </template>
          </v-col>
        </v-row>
        <v-row
          v-if="isProductOutOfStock(product.name)"
          class="red--text text-caption"
        >
          {{ getArgs.store.out_of_stock_text || "Out of stock" }}
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";

export default {
  name: "ProductCard",
  mounted() {
    this.showProductOverlayIfFoundAnchor(this.product.name);
  },
  computed: {
    productName() {
      if (this.isMultiOption === true) {
        return this.product["option:name"];
      }
      return this.product.name;
    },
  },
  props: ["product", "addToCartFunc", "baseUrl", "isMultiOption"],
  mixins: [ChopinProductMixin],
  data() {
    return {
      added: false,
      timeout: 1000,
    };
  },
};
</script>
