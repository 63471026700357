<template>
  <v-container>
    <stripe-element-card
      ref="elementRef"
      :disabled="disableStripeForm"
      :pk="getArgs['platform-stripe-key'] || ''"
      :stripeAccount="method.stripe_method.user_account_id"
      v-if="getArgs['platform-stripe-key']"
    >
    </stripe-element-card>
    <v-btn
      :disabled="disableStripeForm"
      color="primary"
      height="50px"
      block
      outlined
      large
      tile
      @click="pay"
    >
      Pay Now
    </v-btn>
    <v-progress-linear v-if="disableStripeForm" indeterminate />
  </v-container>
</template>
<style lang="scss"></style>
<script>
import { mapGetters } from "vuex";
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import { PaymentsMixin } from "@/lib/PaymentsMixin.js";

export default {
  name: "StripeForm",
  props: ["user", "promptFn", "errorFn", "showAlertOnlyErrorFn"],
  mixins: [PaymentsMixin],
  components: { StripeElementCard },
  data() {
    return {
      stripe: "Stripe",
      disableStripeForm: false,
    };
  },
  computed: {
    ...mapGetters("chopin", ["getArgs"]),
    method() {
      return this.getPaymentMethodHandle(this.stripe);
    },
    methodConf() {
      return this.getPaymentMethodConf(this.stripe);
    },
  },
  methods: {
    async pay() {
      this.disableStripeInput();

      const stripe = this.$refs.elementRef.stripe;
      const card = this.$refs.elementRef.element;
      const billingDetails = {
        name: this.user.name || this.user.email,
        email: this.user.email,
        phone: this.user.phone,
      };

      const resp = await stripe.createPaymentMethod({
        type: "card",
        card: card,
        billing_details: billingDetails,
      });
      // NOTE: pass 4242 4242 4242 4242
      // NOTE: fail 4000 0000 0000 0127
      // NOTE: 3d   4000 0000 0000 3220
      if (resp.error) {
        this.setCheckoutErrorMessage(resp.error.message);
        this.showAlertOnlyErrorFn();
        this.trackException(resp.error.message);
        this.enableStripeInput();
        return;
      }
      const ok = await this.doPayNowStripe(resp.paymentMethod.id);
      if (!ok) {
        this.enableStripeInput();
        return;
      }
      this.checkoutDone();
      return true;
    },
    disableStripeInput() {
      this.disableStripeForm = true;
    },
    enableStripeInput() {
      this.disableStripeForm = false;
    },
    handleStripeCheckoutError(err) {
      this.setCheckoutErrorMessage(err);
      if (err.status === 429) {
        this.checkoutWait();
        this.promptFn();
        return;
      }
      this.checkoutFailed();
      this.showAlertOnlyErrorFn();
      this.trackException();
      this.enableStripeInput();
    },
    async doPayNowStripe(paymentMethodId) {
      let ok = false;
      const resp = await this.checkout(
        this.methodConf,
        null,
        this.showAlertOnlyErrorFn,
        paymentMethodId,
        null
      );
      // NOTE: it's slightly different from doPayNowOffline in PaymentMixin
      if (resp.output.error) {
        this.handleStripeCheckoutError(resp.output.error);
        return ok;
      }
      try {
        await this.confirmStripePaymentIfRequired();
      } catch (e) {
        this.setCheckoutErrorMessage(e);
        this.showAlertOnlyErrorFn();
        this.trackException(e);
        this.enableStripeInput();
        return ok;
      }

      // NOTE: prompt at the very end
      this.promptFn();
      this.trackPurchase();
      ok = true;
      return ok;
    },
    async confirmStripePaymentIfRequired() {
      // NOTE: reverse to find the latest pay order event
      const event = this.getOrder.resp.actions.reverse().find((x) => {
        const criteria = x.name === "Pay order" && x.status === "Success";
        return criteria;
      });
      if (event.detail.response.stripe_payment_response.requires_action) {
        const stripe = this.$refs.elementRef.stripe;
        const card = this.$refs.elementRef.element;
        const clientSecret =
          event.detail.response.stripe_payment_response
            .payment_intent_client_secret;
        if (clientSecret && stripe && card) {
          const resp = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: card,
            },
          });
          if (resp.error) {
            throw new Error(resp.error.message);
          }
          if (resp.paymentIntent.status === "succeeded") {
            this.checkout(
              this.methodConf,
              null,
              this.errorFn,
              null,
              resp.paymentIntent.id
            );
          }
        }
        return;
      }
    },
  },
};
</script>
