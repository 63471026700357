<template>
  <v-container fluid px-0 pt-0 style="padding-bottom: 100px">
    <v-col v-if="getCartWithQuantity.length === 0" class="text-subtitle-2 px-4">
      Elf secretly removed things from your cart 🙈
    </v-col>
    <v-container fluid px-1 pt-0 pb-15 v-else>
      <CartItemsList :enableLink="true" />
      <v-bottom-navigation fixed light height="180">
        <v-row justify="center" align="center" class="px-3 py-5">
          <v-col cols="6" class="px-5 pb-0"> Order Total </v-col>
          <v-col cols="6" class="text-right pr-5 pb-0">
            {{ getFormattedPrice(getAmounts.amounts.total) }}
          </v-col>
          <v-col cols="12" class="text-right pr-5 py-0 font-weight-light">
            <span
              v-if="getAmounts.amounts.discount > 0"
              class="red--text d-block"
            >
              ({{ getFormattedPrice(getAmounts.amounts.discount) }}
              Discount Applied)
            </span>
            <span v-else-if="getDiscountName" class="red--text d-block">
              (Get {{ getDiscountName }})
            </span>
            <span v-if="getAmountFromFreeShipping() > 0" class="d-block">
              {{ getFormattedPrice(getAmountFromFreeShipping()) }}
              away from Free Shipping
            </span>
          </v-col>
          <v-col>
            <v-btn
              class="rounded-sm px-5"
              color="error"
              height="55"
              block
              :disabled="getAmountFromOrderMinimum() > 0"
              @click="trackBeginCheckout"
              :to="checkoutHomeLink"
            >
              <span
                class="
                  text-uppercase text-subtitle-1
                  white--text
                  custom-font
                  poppings
                "
              >
                <span
                  v-if="getAmountFromOrderMinimum() > 0"
                  class="d-block red--text justify-center align-center"
                >
                  <v-icon size="15">fas fa-exclamation-triangle</v-icon>
                  {{ getFormattedPrice(getAmountFromOrderMinimum()) }} away from
                  Checkout
                </span>
                <span v-else> GO TO CHECKOUT </span>
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-bottom-navigation>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";
import CartItemsList from "@/components/checkout/CartItemsList";

export default {
  name: "CartDrawer",
  mixins: [ChopinProductMixin],
  components: { CartItemsList },
  computed: {
    ...mapGetters("cart", [
      "getCartWithQuantity",
      "getAmounts",
      "getOrder",
      "getShipping",
      "getAfterDiscount",
      "getDiscountName",
    ]),
  },
  methods: {
    getAmountFromOrderMinimum() {
      if (!this.getOrder.order_minimum) {
        return 0;
      }
      return this.getOrder.order_minimum - this.getAfterDiscount;
    },
    getAmountFromFreeShipping() {
      if (!this.getShipping.hasFreeShippingAmt) {
        return 0;
      }
      return this.getShipping.freeShippingAmt - this.getAfterDiscount;
    },
    trackBeginCheckout() {
      this.$gtag.event("begin_checkout", {
        value: this.getAmounts.amounts.total,
      });
    },
  },
};
</script>
