<template>
  <v-row no-gutters justify="center" align="center" class="dial bordered">
    <v-col cols="3" class="centered bordered square">
      <v-btn
        @click="removeFromCartAndTrack(product)"
        tile
        elevation="0"
        x-small
        dense
        class="fill-height square px-0"
      >
        <v-icon small>mdi-minus</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="6" class="centered bordered wide-square">
      <v-text-field
        flat
        dense
        filled
        readonly
        solo
        hide-details
        v-model="getCart[product.name].quantity"
        style="margin: 0 8px;"
      >
      </v-text-field>
    </v-col>
    <v-col cols="3" class="bordered square">
      <v-btn
        @click="addToCartAndTrack(product)"
        tile
        elevation="0"
        x-small
        dense
        class="fill-height square px-0"
        :disabled="product.max_qty_int === getCart[product.name].quantity"
      >
        <v-icon small>mdi-plus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.dial {
  height: 35px;
  width: 120px;
}
.fill-height {
  height: 100% !important;
}
.square {
  height: 35px;
  width: 35px;
}
.wide-square {
  height: 35px;
  width: 60px;
}
.centered {
  text-align: center;
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";

export default {
  name: "ProductQuantityDial",
  props: ["product"],
  mixins: [ChopinProductMixin],
  computed: {
    ...mapGetters("cart", ["getCart"]),
  },
  methods: {
    ...mapActions("cart", ["addToCart", "removeFromCart"]),
    addToCartAndTrack(p) {
      this.addToCart(p);
      this.sendAddToCartEvent(p.name);
    },
    removeFromCartAndTrack(p) {
      this.removeFromCart(p);
      this.sendRemoveFromCartEvent(p.name);
    },
  },
};
</script>
