import Vue from "vue";
import Vuex from "vuex";
import chopin from "@/store/chopin";
import cart from "@/store/cart";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

// TODO: right now we cannot combine cache w/ different url
const getStoreCachePath = () => {
  let key;
  key = window.location.pathname.split("/category")[0];
  key = key.split("/checkout")[0];
  return "__chopout__" + key;
};

const vuexLocal = new VuexPersistence({
  key: getStoreCachePath(),
  storage: window.localStorage,
  modules: ["cart"],
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { chopin, cart },
  plugins: [vuexLocal.plugin],
});
