<template>
  <v-container style="height: 100%; width: 100%">
    <Products
      v-if="searchBarPayload.keyword"
      :products="getSearchResult(searchBarPayload.keyword)"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Products from "@/components/product/Products";

export default {
  name: "SearchDisplay",
  props: ["searchBarPayload"],
  components: { Products },
  computed: {
    ...mapGetters("chopin", ["getSearchIndex", "getArgs"]),
  },
  methods: {
    getSearchResult(keyword) {
      const result = this.getSearchIndex.search(keyword);
      const included = {};
      result.forEach((x) => {
        const optionName = x["option:name"];
        if (optionName !== undefined) {
          included[optionName] = x;
        } else {
          included[x.name] = x;
        }
      });
      return Object.values(included);
    },
  },
};
</script>
