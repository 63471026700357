<template>
  <v-container class="px-0">
    <v-row no-gutters class="my-10">
      <template v-if="layout === 'small'">
        <v-col
          cols="6"
          sm="4"
          class="px-0 py-1"
          v-for="(k, i) in categoryPrompts"
          :key="i + '-tabs'"
        >
          <router-link
            :to="categoryHomeLink(k.name)"
            style="text-decoration: none;"
            class="black--text"
          >
            <v-hover v-slot="{ hover }">
              <v-card tile flat class="text-center text-subtitle-2 bg-cover">
                <v-img
                  :src="k.src"
                  cover
                  height="60"
                  class="category-prompt-img"
                  :class="{ 'on-hover': hover }"
                >
                  <v-row
                    class="my-auto"
                    align="center"
                    justify="center"
                    style="height: 100%"
                  >
                    <v-col>
                      {{ getCategoryName(k.name) }}
                    </v-col>
                  </v-row>
                </v-img>
              </v-card>
            </v-hover>
          </router-link>
        </v-col>
        <v-col
          cols="6"
          sm="4"
          class="px-0 py-1"
          v-if="hasMore"
          style="height: 100%"
        >
          <router-link
            :to="categoryCollageShowMoreLink"
            style="text-decoration: none;"
            class="black--text"
          >
            <v-card
              tile
              flat
              class="text-center text-subtitle-2 bg-cover"
              height="60"
            >
              <v-row
                class="my-auto"
                align="center"
                justify="center"
                style="height: 100%"
              >
                <v-col>
                  Show More
                </v-col>
              </v-row>
            </v-card>
          </router-link>
        </v-col>
      </template>
      <template v-else>
        <v-col
          cols="6"
          sm="4"
          class="px-0 py-1"
          v-for="(k, i) in categoryPrompts"
          :key="i + '-enlarged'"
        >
          <router-link
            :to="categoryHomeLink(k.name)"
            style="text-decoration: none;"
            class="black--text"
          >
            <v-hover v-slot="{ hover }">
              <v-card tile flat class="text-center text-subtitle-2">
                <v-img
                  :src="k.src"
                  cover
                  max-height="125"
                  class="category-prompt-img"
                  :class="{ 'on-hover': hover }"
                />
                {{ getCategoryName(k.name) }}
              </v-card>
            </v-hover>
          </router-link>
        </v-col>
        <v-col cols="6" sm="4" class="px-0 py-1" v-if="hasMore">
          <router-link
            :to="categoryCollageShowMoreLink"
            style="text-decoration: none;"
            class="black--text"
          >
            <v-card
              title
              flat
              class="text-center text-subtitle-2"
              style="height: 100%"
            >
              <v-row align="center" justify="center" style="height: 100%">
                <v-col>
                  Show More
                </v-col>
              </v-row>
            </v-card>
          </router-link>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.category-prompt-img:not(.on-hover) .v-image__image {
  box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.5);
}
</style>

<script>
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";

export default {
  name: "CategoryCollage",
  props: ["catalogByCategory", "layout", "n"],
  mixins: [ChopinProductMixin],
  computed: {
    numCategories() {
      return Object.keys(this.catalogByCategory).length;
    },
    hasMore() {
      if (this.n < 0) {
        return false;
      }
      return this.numCategories > this.n;
    },
    categoryPrompts() {
      if (!this.catalogByCategory) {
        return [];
      }
      let useN = this.n;
      if (useN < 0) {
        useN = this.numCategories;
      }
      return Object.entries(this.catalogByCategory)
        .slice(0, useN)
        .map((k) => {
          const [category, products] = k;
          let src;
          products.some((x) => {
            if (x.is_ads !== true) {
              src = this.getProductImageUrl(x.name);
              return true;
            }
          });
          return {
            name: category,
            src: src,
          };
        });
    },
  },
};
</script>
