<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        v-for="(method, i) in getPaymentMethodsByTabName('SubmitOrder')"
        :key="i"
      >
        <v-btn
          block
          tile
          height="50px"
          :color="method.conf.color"
          style="text-decoration: none !important;"
          @click="doPayNowOffline(method.conf, promptFn, errorFn)"
        >
          {{ method.conf.display }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { PaymentsMixin } from "@/lib/PaymentsMixin.js";

export default {
  name: "PayCash",
  props: ["user", "promptFn", "errorFn"],
  mixins: [PaymentsMixin],
};
</script>
