import Vue from "vue";
import i18n from "@/i18n";
import {
  Amounts,
  Order,
  ShippingParams,
  SubtotalThresholdPercentDiscountByCategory,
  SubtotalThresholdPercentDiscount,
  SubtotalThresholdDollarDiscountByCategory,
  SubtotalThresholdDollarDiscount,
} from "@apiobuild/penny";

const state = () => ({
  Cart: {},
  Order: null,
  Discount: null,
  Shipping: null,
  Amounts: null,
  CheckoutStatus: null,
  CheckoutErrorMessage: null,
});
const getters = {
  getCheckoutErrorMessage: (state) => {
    if (state.CheckoutErrorMessage) {
      return state.CheckoutErrorMessage.message || state.CheckoutErrorMessage;
    }
    return state.CheckoutErrorMessage;
  },
  getCheckoutStatus: (state) => {
    return state.CheckoutStatus;
  },
  getCart: (state) => {
    return state.Cart;
  },
  getOrder: (state) => {
    return state.Order;
  },
  getDiscount: (state) => {
    return state.Discount;
  },
  getDiscountVars: (state) => {
    if (!state.Discount) {
      return null;
    }
    const constructor = state.Discount.constructor;
    const rule = state.Discount.rule;

    let vars;
    vars = {
      thresholdRaw: rule.threshold,
      dollarsRaw: rule.dollars_off,
      percent: rule.percent_off,
      category: rule.category,
    };
    let format;
    switch (constructor) {
      case SubtotalThresholdPercentDiscountByCategory:
        format = (vars) =>
          `${vars.percent}% off with order of ${vars.category} items over ${vars.threshold}`;
        break;
      case SubtotalThresholdPercentDiscount:
        format = (vars) =>
          `${vars.percent}% off with order over ${vars.threshold}`;
        break;
      case SubtotalThresholdDollarDiscountByCategory:
        format = (vars) =>
          `${vars.dollars} off with order of ${vars.category} items over ${vars.threshold}`;
        break;
      case SubtotalThresholdDollarDiscount:
        format = (vars) =>
          `${vars.dollars} off with order over ${vars.threshold}`;
        break;
    }
    vars["format"] = format;
    return vars;
  },
  getDiscountName: (state, getters) => {
    if (!state.Discount) {
      return null;
    }
    const vars = getters.getDiscountVars;
    if (!vars.format) {
      return null;
    }
    if (vars.dollarsRaw) {
      vars.dollars = i18n.n(vars.dollarsRaw, "currency");
    }
    vars.threshold = i18n.n(vars.thresholdRaw, "currency");
    return vars.format(vars);
  },
  getShipping: (state) => {
    return state.Shipping;
  },
  getAmounts: (state) => {
    return state.Amounts || {};
  },
  getAfterDiscount: (state, getters) => {
    if (getters.getAmounts) {
      return (
        getters.getAmounts.amounts.subtotal -
        getters.getAmounts.amounts.discount
      );
    }
  },
  getCartWithQuantity: (state) => {
    const cartWithQuantity = Object.entries(state.Cart)
      .filter((pair) => {
        return pair[1].quantity > 0;
      })
      .map(([sku, item]) => {
        return {
          sku: sku,
          quantity: item.quantity,
        };
      });
    return cartWithQuantity;
  },
  getTotalQuantity: (state) => {
    let total = 0;
    Object.entries(state.Cart).forEach((pair) => {
      if (pair[1].quantity > 0) {
        total += pair[1].quantity;
      }
    });
    return total;
  },
  getFreeShippingVars: (state) => {
    const shipping = state.Shipping;
    const vars = {};
    vars.freeShippingAmt = shipping.freeShippingAmt;
    if (shipping.hasFreeShippingAmt) {
      vars.format = (vars) => `Free shipping with order over ${vars.amount}`;
    }
    return vars;
  },
  requireShipping: (state, getters) => {
    if (
      !getters.getShipping.qualifyFreeShipping ||
      !getters.getAmounts.amounts
    ) {
      return false;
    }
    const output = !getters.getShipping.qualifyFreeShipping(
      getters.getAfterDiscount
    );
    return output;
  },
  getProducts: (state) => {
    const items = {};
    Object.values(state.Cart).forEach((cartItem) => {
      if (cartItem.quantity > 0) {
        const item = {
          sku: cartItem.sku,
          order_qty: cartItem.quantity,
          price: cartItem.price,
          // NOTE: for the submit endpoint products payload
          name: cartItem.sku,
          category: cartItem.category,
        };
        items[cartItem.sku] = item;
      }
    });
    return items;
  },
};
const actions = {
  freshCart({ commit, getters }, payload) {
    const updated = {};

    if (payload && payload.products) {
      Object.entries(getters.getCart).forEach(([k, v]) => {
        const product = payload.products[k];
        if (product) {
          v.max_qty_int = product.max_qty_int;
          v.quantity = Math.min(v.quantity, product.max_qty_int);
          updated[k] = v;
        }
      });
    }

    commit("freshCart", updated);
  },
  initAmounts({ commit, state }, payload) {
    const amount = new Amounts(payload.taxRate, state.Shipping, state.Discount);
    commit("initAmounts", amount);
  },
  updateAmounts({ commit, getters }) {
    const updated = getters.getAmounts.setTotal(getters.getProducts);
    commit("updateAmounts", updated);
  },
  updateAmountsWithShippingOption({ dispatch, commit }, payload) {
    commit("updateShippingOption", payload);
    dispatch("updateAmounts");
  },
  initOrder({ commit }, payload) {
    const getSubmitOrderAction = (resp) => {
      if (!resp.actions) {
        return;
      }
      const submitOrderAction = resp.actions.filter(
        (o) => o.name === "Submit order"
      );
      if (getSubmitOrderAction && getSubmitOrderAction.length > 0) {
        return submitOrderAction[0];
      }
    };
    const getOrderNumberFromResponse = (resp) => {
      // TODO: can be done better -_-
      const action = getSubmitOrderAction(resp);
      if (action) {
        const detail = action.detail.response[0];
        if (detail) {
          return {
            short: detail.order_number_short,
            long: detail.order_number,
          };
        }
      }
    };
    const getOrderTotalFromResponse = (resp) => {
      const action = getSubmitOrderAction(resp);
      if (action) {
        return action.detail.response[0].order_total;
      }
    };

    commit(
      "initOrder",
      new Order(payload, {
        getOrderNumberFromResponse,
        getOrderTotalFromResponse,
      })
    );
  },
  initDiscount({ commit, state }, payload) {
    const discountType = payload.type;
    const config = payload.config;

    let discount;
    if (config) {
      // TODO: simplify this, it's so complicated right now
      const args = {
        rule: config,
        order: state.Order,
      };
      if (discountType === "percent" && config.category) {
        discount = new SubtotalThresholdPercentDiscountByCategory(
          ...Object.values(args)
        );
      } else if (discountType === "percent" && !config.category) {
        discount = new SubtotalThresholdPercentDiscount(...Object.values(args));
      } else if (discountType === "dollars" && config.category) {
        discount = new SubtotalThresholdDollarDiscountByCategory(
          ...Object.values(args)
        );
      } else if (discountType === "dollars" && !config.category) {
        discount = new SubtotalThresholdDollarDiscount(...Object.values(args));
      }
    }
    commit("initDiscount", discount);
  },
  initShipping({ commit }, payload) {
    const shipping = new ShippingParams(
      payload.hasFreeShippingAmt,
      payload.freeShippingAmt,
      payload.shippingOptions,
      payload.shippingAreas
    );
    commit("initShipping", shipping);
  },
  addToCart({ dispatch, commit }, payload) {
    commit("updateCart", {
      sku: payload.name,
      max_qty_int: payload.max_qty_int,
      price: payload.price,
      delta: 1,
      category: payload.category,
    });
    dispatch("updateAmounts");
  },
  removeFromCart({ dispatch, commit }, payload) {
    commit("updateCart", {
      sku: payload.name,
      max_qty_int: payload.max_qty_int,
      price: payload.price,
      delta: -1,
      category: payload.category,
    });
    dispatch("updateAmounts");
  },
  deleteFromCart({ dispatch, commit }, payload) {
    commit("updateCartToQuantity", {
      sku: payload.name,
      max_qty_int: payload.max_qty_int,
      price: payload.price,
      quantity: 0,
    });
    dispatch("updateAmounts");
  },
  resetCheckoutStatus({ commit }) {
    commit("updateCheckoutStatus", null);
  },
  checkoutInProgress({ commit }) {
    commit("updateCheckoutStatus", "running");
  },
  checkoutSuccess({ commit }) {
    commit("updateCheckoutStatus", "success");
    commit("freshCart");
    commit("updateAmounts");
  },
  checkoutWait({ commit }) {
    commit("updateCheckoutStatus", "waiting");
  },
  checkoutFailed({ commit }) {
    commit("updateCheckoutStatus", "failed");
  },
  setCheckoutErrorMessage({ commit }, payload) {
    commit("setCheckoutErrorMessage", payload);
  },
};
const mutations = {
  freshCart(state, payload) {
    const updated = payload || {};
    Vue.set(state, "Cart", updated);
  },
  updateCart(state, payload) {
    const currentQuantity = (state.Cart[payload.sku] || {}).quantity;
    let updatedQuantity = (currentQuantity || 0) + payload.delta;

    if (updatedQuantity <= 0) {
      updatedQuantity = 0;
    } else if (updatedQuantity > payload.max_qty_int) {
      updatedQuantity = payload.max_qty_int;
    }
    payload.quantity = updatedQuantity;
    Vue.set(state.Cart, payload.sku, payload);
  },
  updateCartToQuantity(state, payload) {
    if (payload.quantity === 0) {
      Vue.delete(state.Cart, payload.sku);
      return;
    }
    Vue.set(state.Cart, payload.sku, payload);
  },
  initOrder(state, payload) {
    state.Order = payload;
  },
  initDiscount(state, payload) {
    state.Discount = payload;
  },
  initShipping(state, payload) {
    state.Shipping = payload;
  },
  initAmounts(state, payload) {
    state.Amounts = payload;
  },
  updateAmounts(state, payload) {
    state.Amounts = payload;
  },
  updateShippingOption(state, payload) {
    Vue.set(state.Shipping, "shippingOptionSelected", payload);
  },
  updateCheckoutStatus(state, payload) {
    state.CheckoutStatus = payload;
  },
  setCheckoutErrorMessage(state, payload) {
    state.CheckoutErrorMessage = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
