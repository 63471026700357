<template>
  <v-container>
    <v-row class="px-3" style="padding-top: 60px">
      <SearchBar :searchBarPayload="searchBarPayload" />
    </v-row>
    <v-row class="py-3" style="height: 100vh">
      <SearchDisplay :searchBarPayload="searchBarPayload" />
    </v-row>
  </v-container>
</template>

<script>
import SearchBar from "@/components/search/SearchBar";
import SearchDisplay from "@/components/search/SearchDisplay";

export default {
  name: "SearchView",
  components: { SearchBar, SearchDisplay },
  data() {
    return {
      searchBarPayload: {
        keyword: "",
      },
    };
  },
};
</script>
