<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col cols="12" v-if="hasShippingOptions">
        <span class="text-h6 text-capitalize">
          Shipping Option
          <span v-if="getFreeShippingName" style="text-transform: none">
            ({{ getFreeShippingName }})
          </span>
        </span>
        <v-select
          v-model="payloadMutable.option"
          :items="getShippingOptionsWithDisplay"
          item-text="optionDisplay"
          item-value="optionName"
          :rules="requireShipping ? [requireShippingRule] : []"
          required
          return-object
          @change="updateShippingOption()"
        ></v-select>
      </v-col>
    </v-row>

    <v-row v-if="getAddressInputField.hide !== true">
      <v-col cols="12">
        <span class="text-h6 text-capitalize"> Shipping Address </span>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-text-field
          v-model="payloadMutable.address1"
          :rules="getAddressInputField.required === true ? [requiredRule] : []"
          label="Address 1"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-text-field
          v-model="payloadMutable.address2"
          label="Address 2"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" class="py-0">
        <v-text-field
          v-model="payloadMutable.city"
          :rules="getAddressInputField.required === true ? [requiredRule] : []"
          label="City"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" class="py-0">
        <v-text-field
          v-model="payloadMutable.state"
          :rules="getAddressInputField.required === true ? [requiredRule] : []"
          label="State/Province/Region"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" class="py-0">
        <v-text-field
          v-model="payloadMutable.zipcode"
          :rules="zipcodeRules"
          label="Zipcode/Postal Code"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" class="py-0">
        <v-autocomplete
          v-model="payloadMutable.country"
          :items="getStore['country-names']"
          :rules="countryFieldRules"
          label="Country"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { ChopinMixin } from "@/lib/ChopinMixin.js";

export default {
  name: "ShippingForm",
  mixins: [ChopinMixin],
  props: ["payload"],
  data() {
    const requiredRule = (v) => !!v || "This field is required";
    const requireShippingRule = (v) => {
      return !!v.optionName || "Must select shipping option";
    };
    const ifZipcodeAvailable = (v) => {
      if (this.getZipCodes.length > 0 && !this.getZipCodes.includes(v)) {
        return "Zipcode not available for shipping";
      }
      return true;
    };

    const ifCountryAllowed = (v) => {
      if (!this.getStore["country-names"].includes(v)) {
        return "Country not available for shipping";
      }
      return true;
    };

    const defaultShippingOption = {
      optionsName: null,
      optionCost: null,
      optionDisplay: "",
    };
    return {
      requireShippingRule,
      ifZipcodeAvailable,
      ifCountryAllowed,
      defaultShippingOption,
      requiredRule,
    };
  },
  created() {
    this.payloadMutable.option = this.defaultShippingOption;
    this.payloadMutable.country = this.getStore["country-names"][0];
  },
  computed: {
    ...mapGetters("chopin", [
      "getStore",
      "getArgs",
      "getZipCodes",
      "getShippingOptions",
      "getAddressInputField",
      "hasShippingOptions",
    ]),
    ...mapGetters("cart", ["requireShipping", "getFreeShippingVars"]),
    payloadMutable() {
      return this.payload;
    },
    getFreeShippingName() {
      const vars = this.getFreeShippingVars;
      if (vars.freeShippingAmt) {
        vars.amount = this.$i18n.n(vars.freeShippingAmt, "currency");
      }
      if (vars.format) {
        return vars.format(vars);
      }
      return null;
    },
    countryFieldRules() {
      let countryFieldRules = [];
      if (this.getAddressInputField.required === true) {
        countryFieldRules.push(this.requiredRule);
      }
      countryFieldRules.push(this.ifCountryAllowed);
      return countryFieldRules;
    },
    zipcodeRules() {
      let zipcodeRules = [];
      if (this.getAddressInputField.required === true) {
        zipcodeRules.push(this.requiredRule);
      }
      if (this.getZipCodes.length > 0) {
        zipcodeRules.push(this.ifZipcodeAvailable);
      }
      return zipcodeRules;
    },
    getShippingOptionsWithDisplay() {
      let options = this.getShippingOptions.map((x) => ({
        optionName: x.optionName,
        optionCost: x.optionCost,
        optionDisplay: `${x.optionName}: ${this.getFormattedPrice(
          x.optionCost
        )}`,
      }));
      options = [this.defaultShippingOption].concat(options);
      return options;
    },
  },
  methods: {
    ...mapActions("cart", ["updateAmountsWithShippingOption"]),
    updateShippingOption() {
      this.updateAmountsWithShippingOption(this.payload.option.optionName);
    },
  },
};
</script>
