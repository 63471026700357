<template>
  <v-container class="pt-0 mt-0">
    <v-row class="font-weight-light my-5 mx-3">
      <v-col class="px-0 mx-0 pb-0 mb-0">
        <span class="text-h5 d-block" v-if="categoryName">
          {{ getCategoryName(categoryName) }}
        </span>
        <span class="text-h5 d-block" v-else>
          All Products
        </span>
        <span class="text-caption" v-if="categoryName">
          {{ getNumProductsByCategory }} Products
        </span>
        <span class="text-caption" v-else> {{ numProducts }} Products </span>
      </v-col>
    </v-row>
    <v-row v-bind:class="{ 'pb-15': end }">
      <v-col cols="6" sm="3" v-for="(p, i) in getSlicedProducts" :key="i">
        <AdCard v-if="p.is_ads" :product="p" />
        <ProductCard
          v-else
          :baseUrl="baseUrl"
          :product="p"
          :addToCartFunc="addToCartAndNotification"
          :isMultiOption="p['option:name'] !== undefined"
        />
      </v-col>
      <v-col v-if="hasMore" class="text-center">
        <v-btn tile outlined large :to="categoryHomeLink(categoryName)">
          Show More
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="getOrDefaultShowPage">
      <v-col class="pt-0 pb-15">
        <div class="text-center">
          <v-pagination v-model="page" :length="numPages"></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-snackbar
      light
      bottom
      text
      :color="snackbar.color"
      v-model="snackbar.added"
      :timeout="snackbar.timeout"
    >
      <v-col class="pa-0 text-center text-uppercase">
        {{ snackbar.text }}
      </v-col>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";
import ProductCard from "@/components/product/ProductCard";
import AdCard from "@/components/product/AdCard";

export default {
  name: "Products",
  data() {
    return {
      page: 1,
      snackbar: {
        color: "success",
        added: false,
        timeout: 1000,
        text: "ADDED",
      },
      // NOTE: need to be greater than 8
      numProductsPerPage: 20,
    };
  },
  props: [
    "products",
    "categoryName",
    "baseUrl",
    "end",
    "showPage",
    "pageN",
    "n",
  ],
  mixins: [ChopinProductMixin],
  components: { ProductCard, AdCard },
  computed: {
    ...mapGetters("chopin", ["getArgs"]),
    ...mapGetters("cart", ["getCart"]),
    getOrDefaultShowPage() {
      if (this.showPage === true) {
        return true;
      }
      return false;
    },
    hasMore() {
      return this.categoryName && this.n
        ? this.n < this.getNumProductsByCategory
        : false;
    },
    getOrDefaultN() {
      if (this.n === undefined) {
        return this.numProductsPerPage;
      }
      return this.n;
    },
    numPages() {
      return Math.ceil(this.getNumProductsByCategory / this.numProductsPerPage);
    },
    getNumProductsByCategory() {
      return Object.values(this.products).length;
    },
    getSlicedProducts() {
      const start = (this.page - 1) * this.numProductsPerPage;
      const end = start + this.getOrDefaultN;
      return this.products.slice(start, end);
    },
  },
  methods: {
    ...mapActions("cart", ["addToCart"]),
    // NOTE: consolidate into one
    addToCartAndNotification(p) {
      this.snackbar.added = true;
      this.snackbar.text = "ADDED";
      this.snackbar.color = "success";
      if (
        this.getCart[p.name] &&
        this.getCart[p.name].quantity === p.max_qty_int
      ) {
        this.snackbar.text = `Limit ${p.max_qty_int} Per Customer`;
        this.snackbar.color = "error";
      }
      this.addToCart(p);
      this.sendAddToCartEvent(p.name);
    },
  },
};
</script>
