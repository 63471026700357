import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const defaultLocale = "en";
const locale = (navigator.languages && navigator.languages[0]) || defaultLocale;

const i18n = new VueI18n({ locale: locale, silentFallbackWarn: true });

export default i18n;
