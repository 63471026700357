import { mapGetters, mapActions } from "vuex";
import StoreAlias from "@/store-alias.json";

export const ChopinMixin = {
  data() {
    return {
      userId: null,
      storeId: null,
      chopinBackendApiToken: null,
      alias: null,
      aliases: StoreAlias,
      cookieExpires: 28 * 24 * 60 * 60,
      customCurrencies: {
        OSC: {
          symbol: "⭐",
          maximumFractionDigits: 0,
        },
      },
    };
  },
  computed: {
    ...mapGetters("cart", ["getCartWithQuantity", "getTotalQuantity"]),
    ...mapGetters("chopin", [
      "getArgs",
      "getStore",
      "argsFetched",
      "getProductLookup",
    ]),
    getLinkParams() {
      if (this.alias) {
        return { alias: this.alias };
      }
      return { user: this.userId, store: this.storeId };
    },
    storeHomeLink() {
      return {
        name: this.getLinkName("store"),
        params: this.getLinkParams,
      };
    },
    showAllLink() {
      return {
        name: this.getLinkName("browse"),
        params: this.getLinkParams,
      };
    },
    searchLink() {
      return {
        name: this.getLinkName("search"),
        params: this.getLinkParams,
      };
    },
    category() {
      return this.$route.params.category;
    },
    categories() {
      if (this.getArgs["catalog-by-category"]) {
        return Object.keys(this.getArgs["catalog-by-category"]);
      }
      return [];
    },
    brandColor() {
      return this.getArgs.store.color;
    },
    numProducts() {
      return this.getArgs.catalog ? this.getArgs.catalog.length : 0;
    },
    isCustomDomain() {
      return process.env.VUE_APP_DOMAIN === "custom";
    },
  },
  methods: {
    ...mapActions("chopin", [
      "fetchStoreArgs",
      "resetFetchStoreAction",
      "initFlexSearchIndex",
    ]),
    ...mapActions("cart", [
      "freshCart",
      "initOrder",
      "initDiscount",
      "initShipping",
      "initAmounts",
      "updateAmounts",
      "resetCheckoutStatus",
      "setCheckoutErrorMessage",
    ]),
    init() {
      this.setCheckoutErrorMessage(null);
      this.resetCheckoutStatus();
      this.freshCart({ products: this.getProductLookup });
      this.initOrder({
        currencyCode: this.getArgs["currency-code"],
        locale: this.$vuetify.lang.current,
        order_minimum: this.getStore.order_minimum,
      });
      this.initDiscount({
        config: this.getStore.discount.config,
        type: this.getStore.discount.type,
      });
      this.initShipping({
        hasFreeShippingAmt: this.getStore.has_free_shipping_amt,
        freeShippingAmt: this.getStore.free_shipping_amt,
        shippingOptions: this.getStore.shipping,
        shippingAreas: this.getStore.shipping_areas,
      });
      this.initAmounts({
        taxRate: this.getStore.tax_rate,
      });
      this.updateAmounts();

      this.$i18n.setNumberFormat(
        this.$i18n.locale,
        this.getCurrencyNumberFormat()
      );
      this.initFlexSearchIndex(this.getArgs.catalog);
    },
    getCurrencyNumberFormat() {
      const currencyCode = this.getArgs["currency-code"];
      const currency = {
        style: "currency",
        currency: currencyCode,
        currencyDisplay: "symbol",
      };
      const customCurrency = this.customCurrencies[currencyCode];
      if (customCurrency) {
        for (const [key, value] of Object.entries(customCurrency)) {
          currency[key] = value;
        }
      }
      return { currency };
    },
    updatePageMeta() {
      const defaultLogo =
        this.getStore.logo_url || "https://apiobuild.com/images/favicon.png";
      const defaultTitle = this.getStore.name || "Chopin by Apio";
      const defaultDescription =
        this.getStore.description ||
        "Create your own online store in 30 seconds with Chopin from Apio";
      const defaultImage =
        this.getStore.background_image_url ||
        "https://apiobuild.com/images/logo.png";

      const setMetaTagContent = (tag, content) => {
        tag.content = content;
      };

      const getMetaTagAndSetContent = (tags, content) => {
        tags.forEach((x) =>
          setMetaTagContent(document.getElementById(x), content)
        );
      };

      const favicon = document.getElementById("favicon");
      favicon.href = defaultLogo;

      const pageTitle = document.getElementById("page-title");
      pageTitle.text = defaultTitle;

      getMetaTagAndSetContent(
        ["meta-title", "meta-twitter-title"],
        defaultTitle
      );
      getMetaTagAndSetContent(
        ["meta-description", "meta-og-description", "meta-twitter-description"],
        defaultDescription
      );
      getMetaTagAndSetContent(
        ["meta-image", "meta-twitter-image"],
        defaultImage
      );
      getMetaTagAndSetContent(
        ["meta-url"],
        window.location.origin + this.$route.path
      );
    },
    setUserIdAndStoreId() {
      const userId = this.$route.params.user;
      const storeId = this.$route.params.store;
      let alias = this.$route.params.alias || userId;
      if (this.isCustomDomain) {
        alias = window.location.hostname;
      }
      this.userId = userId;
      this.storeId = storeId;

      if (alias && alias in this.aliases) {
        const params = this.aliases[alias];
        this.alias = alias;
        if (this.isCustomDomain) {
          this.chopinBackendApiToken = params.token;
        }
        this.userId = params.user;
        this.storeId = params.store;
      }
    },
    getRouterPrefix() {
      if (this.isCustomDomain) {
        return "custom-domain";
      }
      return "alias";
    },
    getLinkName(name) {
      if (this.alias) {
        return `${this.getRouterPrefix()}-${name}`;
      }
      return name;
    },
    initGTagWithoutStore() {
      this.$gtag.config({
        page_path: this.$route.path,
        cookie_expires: this.cookieExpires,
      });
    },
    initGtagWithStore() {
      this.$gtag.config({
        page_title: this.getStore.name,
        page_path: this.$route.path,
        cookie_expires: this.cookieExpires,
      });
      this.$gtag.set({
        currency: this.getStore.currency,
      });
    },
    getFormattedPrice(price) {
      const currencyCode = this.getArgs["currency-code"];
      const currency = this.customCurrencies[currencyCode];
      let formattedPrice = this.$n(price, "currency");
      if (currency) {
        formattedPrice =
          formattedPrice.slice(currencyCode.length + 1) + currency.symbol;
      }
      return formattedPrice;
    },
    goHome() {
      this.resetFetchStoreAction(null);
      this.$gtag.event("redirected_to_intro");
      this.$router.push({ name: "intro" });
    },
    async goToStore() {
      const payload = {
        userId: this.userId,
        storeId: this.storeId,
        ttoken:
          this.chopinBackendApiToken ||
          process.env.VUE_APP_CHOPIN_BACKEND_API_TOKEN,
      };
      if (this.argsFetched === null) {
        try {
          await this.fetchStoreArgs(payload);
          this.init();
          this.updatePageMeta();
          this.initGtagWithStore();
        } catch (e) {
          // NOTE: uncomment to debug
          // console.log(e);
          this.goHome();
        }
      }
    },
  },

  async created() {
    this.updatePageMeta();
    this.initGTagWithoutStore();
    this.setUserIdAndStoreId();

    if (!this.isCustomDomain) {
      // empty path
      if (!this.userId || !this.storeId) {
        if (this.$route.name !== "intro") {
          this.goHome();
          return;
        }
        this.resetFetchStoreAction(null);
        return;
      }
      // has path
      await this.goToStore();
      return;
    }
    if (this.isCustomDomain) {
      // alias not found
      if (!this.userId || !this.storeId) {
        // already on chopin page
        if (this.$route.name === "intro") {
          this.resetFetchStoreAction(null);
          return;
        }
        this.goHome();
        return;
      }
      // intro
      if (this.$route.name === "intro") {
        this.resetFetchStoreAction(null);
        return;
      }
      await this.goToStore();
    }
  },
};
