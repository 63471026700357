<template>
  <v-card>
    <v-container>
      <v-col class="text-h5 text-center text-uppercase custom-font poppings">
        Order Summary
      </v-col>
      <v-simple-table fixed-header>
        <tbody>
          <tr v-for="(r, i) in rows" :key="i">
            <!-- titles -->
            <td class="text-capitalize text-subtitle-2">
              <span
                v-bind:class="{
                  'text-subtitle-1 font-weight-bold': r === 'total',
                }"
              >
                {{ r }}
                <span
                  v-if="r === 'discount' && getDiscountName"
                  style="text-transform: none"
                >
                  <br />({{ getDiscountName }})
                </span>
                <span
                  v-if="r === 'shipping' && getShipping.shippingOptionSelected"
                  style="text-transform: none"
                >
                  <br />({{ getShipping.shippingOptionSelected }})
                </span>
                <span
                  v-if="r === 'tax' && getTaxName"
                  style="text-transform: none"
                >
                  <br />({{ getTaxName }})
                </span>
              </span>
            </td>

            <!-- numbers -->
            <td class="text-right">
              <span
                v-bind:class="{
                  'red--text': r === 'discount',
                  'font-weight-bold text-subtitle-1': r === 'total',
                }"
              >
                {{ getFormattedPrice(getAmountsFromMemoryOrVuex()[r]) }}
              </span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-container>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { ChopinMixin } from "@/lib/ChopinMixin.js";
import { PaymentsMixin } from "@/lib/PaymentsMixin.js";

export default {
  name: "OrderSummary",
  mixins: [ChopinMixin, PaymentsMixin],
  computed: {
    ...mapGetters("cart", [
      "getAmounts",
      "getDiscount",
      "getShipping",
      "getDiscountName",
    ]),
    rows() {
      if (this.getDiscount) {
        return ["subtotal", "discount", "shipping", "tax", "total"];
      }
      return ["subtotal", "shipping", "tax", "total"];
    },
    getTaxName() {
      const tax = (this.getAmounts.configs || {}).tax;
      if (tax > 0) {
        return tax;
      }
      return null;
    },
  },
};
</script>
