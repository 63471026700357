<template>
  <v-container class="px-1">
    <v-card v-if="getCheckoutStatus === 'success'">
      <v-row justify="center" align="center">
        <v-col cols="12" class="text-center">
          <v-sheet
            class="mx-auto rounded-circle"
            height="150"
            width="150"
            color="secondary"
          >
            <v-icon size="80" class="gift-icon my-8"> fas fa-gifts </v-icon>
          </v-sheet>
        </v-col>

        <v-card-title> Order Confirmed </v-card-title>

        <v-card-text class="black--text text-center text-subtitle-1">
          Your order is sent to {{ getStore.name }}.
          <span class="mt-3 d-block" v-html="notes(getOrder.method.name)">
          </span>
        </v-card-text>
        <v-divider class="my-3 mx-4" />
        <v-card-text class="black--text text-center text-subtitle-2">
          Order Number: {{ (getOrder.number || {}).short }}
          <span class="mt-3 d-block">
            Thank you for shopping with us! Come again!
          </span>
        </v-card-text>
      </v-row>
    </v-card>
    <!-- <v-card height="300" width="300" class="mx-auto"> -->
    <v-card class="mx-auto" v-if="getCheckoutStatus === 'running'">
      <v-img
        src="https://media.giphy.com/media/Qrca6tBIdqXYXhnB4v/giphy.gif"
      ></v-img>
    </v-card>
    <v-card v-if="getCheckoutStatus === 'waiting'">
      <Wait :clickFn="tryAgainClickFn" />
    </v-card>
  </v-container>
</template>

<style lang="scss">
.gift-icon {
  background: -moz-linear-gradient(top, #e72c83 0%, #a742c6 100%);
  background: -webkit-linear-gradient(top, #e72c83 0%, #a742c6 100%);
  background: linear-gradient(to bottom, #e72c83 0%, #a742c6 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

<script>
import { mapGetters } from "vuex";
import { PaymentsMixin } from "@/lib/PaymentsMixin.js";
import { ChopinMixin } from "@/lib/ChopinMixin.js";
import Wait from "@/components/checkout/Wait.vue";

export default {
  name: "OrderConfirmationNote",
  mixins: [ChopinMixin, PaymentsMixin],
  props: ["tryAgainClickFn"],
  components: { Wait },
  computed: {
    ...mapGetters("chopin", ["getStore"]),
    ...mapGetters("cart", ["getCheckoutStatus", "getAmounts", "getOrder"]),
    total() {
      return this.getAmountsFromMemoryOrVuex().total || 0;
    },
  },
  methods: {
    notes(method) {
      const currencyFormat = (value) => this.getFormattedPrice(value);
      const value = this.getPaymentMethodHandle(method);
      const conf = this.getPaymentMethodConf(method);
      const handle = value.handle;
      const amount = currencyFormat(this.total);

      const offlinePaymentMethodNotes = (
        amount,
        method,
        handle,
        numeric_amount
      ) => {
        if (method === "PaypalMe") {
          return `Please pay ${amount} on <a href="${handle}/${numeric_amount}" target="_blank">Paypal.me</a> to complete your order.`;
        }
        return `Please send ${amount} to ${handle} via ${method} with your name in note.`;
      };

      const payCashPaymentNethodNotes = (amount, note) => {
        if (method === "Manual") {
          return `Please pay ${amount}. The merchant will contact you to confirm the order.`;
        }
        return `Please pay ${amount} ${note}.`;
      };
      const onlinePaymentMethodNotes = (amount) =>
        `We've received your payment of ${amount}.`;

      const noteFnMap = {
        CreditCard: onlinePaymentMethodNotes(amount),
        SendMoney: offlinePaymentMethodNotes(
          amount,
          conf.note_display || method,
          handle,
          this.total
        ),
        SubmitOrder: payCashPaymentNethodNotes(amount, conf.note_display),
      };

      let checkEmailMessage = "";
      if (this.getStore.email_confirmation_enabled) {
        checkEmailMessage = "Check your email for order confirmation.";
      }
      return `${noteFnMap[conf.tab]} ${checkEmailMessage}`;
    },
  },
};
</script>
