<template>
  <v-container class="px-0 mx-0">
    <Board :squares="squares" :winner="winner" @click="click" />

    <v-row>
      <v-col class="text-subtitle-1 text-uppercase">
        <span v-if="stepNumber === 0"> {{ currentPlayer }}'s Turn </span>
        <span v-else-if="stepNumber > 8"></span>
        <span v-else-if="!!winner"> {{ currentPlayer }} Won </span>
        <span v-else> {{ currentPlayer }}'s Turn </span>
      </v-col>
      <v-col cols="12" v-if="!!winner || stepNumber > 8">
        <v-btn color="accent" outlined @click="restart">Play Again</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Board from "@/components/game/TikTokToe/Board.vue";

export default {
  name: "Game",
  components: { Board },
  data() {
    return {
      squares: Array(9).fill(null),
      stepNumber: 0,
      currentPlayer: "X",
      winner: null,
    };
  },
  methods: {
    hasWinner() {
      if (this.winner) return true;
      const squares = this.squares;
      const matches = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
      ];
      for (let i = 0; i < matches.length; i++) {
        const [a, b, c] = matches[i];
        if (
          squares[a] &&
          squares[a] === squares[b] &&
          squares[a] === squares[c]
        ) {
          this.winner = [a, b, c];
          return true;
        }
      }
      return false;
    },
    restart() {
      this.squares = Array(9).fill(null);
      this.stepNumber = 0;
      //   this.currentPlayer = this.currentPlayer;
      this.winner = null;
    },
    click(i) {
      if (this.squares[i] || this.winner) return;
      this.$set(this.squares, i, this.currentPlayer);
      if (!this.hasWinner()) {
        this.stepNumber++;
        this.currentPlayer = this.currentPlayer === "X" ? "O" : "X";
      }
    },
  },
};
</script>
