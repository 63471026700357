<template>
  <v-container fluid class="px-0">
    <v-list>
      <v-list-item v-for="(k, i) in cartWithProductDetails" :key="i">
        <v-row
          style="width: 100%"
          justify="center"
          class="py-2"
          v-if="k.product"
        >
          <v-col cols="3" style="height: 100%" class="d-inline">
            <template v-if="enableLinkDefault">
              <router-link
                class="d-inline"
                :to="`#${optionNameOrName(k.product)}`"
                @click.native="trackViewProduct(k.product.name)"
              >
                <v-img
                  :src="getProductImageUrl(k.sku)"
                  height="100"
                  contain
                ></v-img>
              </router-link>
            </template>
            <template v-else>
              <v-img
                :src="getProductImageUrl(k.sku)"
                height="100"
                contain
              ></v-img>
            </template>
          </v-col>
          <v-col cols="9">
            <v-row no-gutters style="width: 100%">
              <v-row no-gutters style="width: 100%">
                <v-col cols="11">
                  <template v-if="enableLinkDefault">
                    <router-link
                      class="d-inline black--text"
                      :to="`#${optionNameOrName(k.product)}`"
                      @click.native="trackViewProduct(k.product.name)"
                      style="text-decoration: none"
                    >
                      <v-col
                        class="text-truncate text-subtitle-1 px-0 pt-1 pb-0"
                        style="text-decoration: none"
                      >
                        {{ k.product.nickname }}
                      </v-col>
                      <v-col
                        class="font-weight-bold px-0 py-0"
                        style="text-decoration: none"
                      >
                        {{ getFormattedPrice(k.product.price) }}
                      </v-col>
                    </router-link>
                  </template>
                  <template v-else>
                    <v-col
                      class="text-truncate text-subtitle-1 px-0 pt-1 pb-0"
                      style="text-decoration: none"
                    >
                      {{ k.product.nickname }}
                    </v-col>
                    <v-col
                      class="font-weight-bold px-0 py-0"
                      style="text-decoration: none"
                    >
                      {{ getFormattedPrice(k.product.price) }}
                    </v-col>
                  </template>
                </v-col>
                <v-col cols="1" class="text-center">
                  <v-btn
                    @click="deleteFromCartAndTrack(k.product)"
                    elevation="0"
                    dense
                    icon
                  >
                    <v-icon> mdi-trash-can-outline </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-col cols="12" v-if="isProductWithOptions(k.sku)">
                <template v-if="enableLinkDefault">
                  <router-link
                    class="d-inline black--text"
                    :to="`#${optionNameOrName(k.product)}`"
                    @click.native="trackViewProduct(k.product.name)"
                    style="text-decoration: none"
                  >
                    <ProductOptionsCompactDisplay :product="k.product" />
                  </router-link>
                </template>
                <template v-else>
                  <ProductOptionsCompactDisplay :product="k.product" />
                </template>
              </v-col>
              <v-col cols="12" class="text-left py-5">
                <ProductQuantityDial :product="k.product" />
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            inset
            class="pt-0"
            v-if="i < cartWithProductDetails.length - 1"
          >
            <v-divider />
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";
import ProductQuantityDial from "@/components/product/ProductQuantityDial";
import ProductOptionsCompactDisplay from "@/components/product/ProductOptionsCompactDisplay";

export default {
  name: "CartItemsList",
  props: ["enableLink"],
  components: { ProductQuantityDial, ProductOptionsCompactDisplay },
  mixins: [ChopinProductMixin],
  computed: {
    ...mapGetters("cart", ["getCartWithQuantity"]),
    cartWithProductDetails() {
      return Object.values(this.getCartWithQuantity).map((k) => {
        return {
          sku: k.sku,
          quantity: k.quantity,
          product: this.getProductDetails(k.sku),
        };
      });
    },
    enableLinkDefault() {
      if (this.enableLink === undefined) {
        return false;
      }
      return this.enableLink;
    },
  },
  methods: {
    ...mapActions("cart", ["deleteFromCart"]),
    deleteFromCartAndTrack(p) {
      this.sendRemoveFromCartEvent(p.name);
      this.deleteFromCart(p);
    },
    optionNameOrName(product) {
      return product["option:name"] || product.name;
    },
  },
};
</script>
