<template>
  <v-container fluid class="px-0 py-15">
    <v-row
      v-if="getCartWithQuantity.length === 0 && getCheckoutStatus !== 'success'"
      justify="center"
      align="center"
      class="py-5 px-auto"
    >
      <v-col>
        <v-img
          src="https://media.giphy.com/media/dwRwAXIe0tkz0EWFTr/giphy.gif"
          max-width="550"
          class="mx-sm-auto mx-5"
        />
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn
          :to="storeHomeLink"
          color="error"
          height="55"
          width="90%"
          max-width="550"
          class="mx-sm-auto mx-5"
        >
          Continue Shopping
        </v-btn>
      </v-col>
    </v-row>
    <v-container fluid v-else>
      <v-row>
        <v-col cols="12" sm="8">
          <OrderForm />
        </v-col>
        <v-col v-if="!isXs">
          <OrderSummary />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";
import OrderSummary from "@/components/checkout/OrderSummary";
import OrderForm from "@/components/checkout/OrderForm";

export default {
  name: "CheckoutHome",
  components: { OrderSummary, OrderForm },
  mixins: [ChopinProductMixin],
  computed: {
    ...mapGetters("cart", ["getCheckoutStatus", "getCartWithQuantity"]),
    ...mapGetters("chopin", ["getArgs"]),
    isXs() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
};
</script>
