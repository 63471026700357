<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        v-for="(method, i) in getPaymentMethodsByTabName('SendMoney')"
        :key="i"
      >
        <v-btn
          block
          tile
          height="50px"
          :color="method.conf.color"
          @click="doPayNowOffline(method.conf, promptFn, errorFn)"
        >
          <v-img
            v-if="method.conf.icon"
            :src="method.conf.icon"
            width="100px"
            height="50px"
            contain
          />
          <span v-else>{{ method.conf.display || method.conf.name }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { PaymentsMixin } from "@/lib/PaymentsMixin.js";

export default {
  name: "SendMoney",
  props: ["user", "promptFn", "errorFn"],
  mixins: [PaymentsMixin],
};
</script>
