<template>
  <v-container fluid fill-height class="px-0">
    <IntroPage v-if="argsFetched === null" />
    <LoadingStore v-if="argsFetched === false" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import IntroPage from "@/components/intro/IntroPage";
import LoadingStore from "@/components/intro/LoadingStore";

export default {
  name: "Home",
  components: { IntroPage, LoadingStore },
  computed: {
    ...mapGetters("chopin", ["argsFetched"]),
  },
};
</script>
