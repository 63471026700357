import { mapGetters } from "vuex";
import md from "markdown-it";
import iterator from "markdown-it-for-inline";
import StoreAlias from "@/store-alias.json";

export const ChopinProductMixin = {
  data() {
    return {
      defaultImageSrc:
        "https://media.giphy.com/media/3ouX6KNb8I430s0Ffz/giphy.gif",
      userId: null,
      storeId: null,
      alias: null,
      aliases: StoreAlias,
      customCurrencies: {
        OSC: {
          symbol: "⭐",
          maximumFractionDigits: 0,
        },
      },
    };
  },
  computed: {
    ...mapGetters("chopin", ["getArgs", "getProductLookup"]),
    ...mapGetters("cart", ["getCart", "getProducts"]),
    numProducts() {
      return this.getArgs.catalog ? this.getArgs.catalog.length : 0;
    },
    getLinkParams() {
      if (this.alias) {
        return { alias: this.alias };
      }
      return { user: this.userId, store: this.storeId };
    },
    storeHomeLink() {
      return {
        name: this.getLinkName("store"),
        params: this.getLinkParams,
      };
    },
    showAllLink() {
      return {
        name: this.getLinkName("browse"),
        params: this.getLinkParams,
      };
    },
    categoryCollageShowMoreLink() {
      return {
        name: this.getLinkName("categories"),
        params: this.getLinkParams,
      };
    },
    checkoutHomeLink() {
      return {
        name: this.getLinkName("checkout"),
        params: this.getLinkParams,
      };
    },
    category() {
      return this.$route.params.category;
    },
    getCategoryIndexLookup() {
      const lookup = {};
      Object.keys(this.getArgs["catalog-by-category"]).forEach((x, i) => {
        lookup[x] = i;
      });
      return lookup;
    },
    outOfStockText() {
      const DefaultOutOfStockText = "Coming Soon";
      if (!this.getArgs.store) {
        return DefaultOutOfStockText;
      }
      if (this.getArgs.store.out_of_stock_text) {
        return this.getArgs.store.out_of_stock_text;
      }
      return DefaultOutOfStockText;
    },
    overlayProductNameTruncate() {
      let truncate = false;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          truncate = false;
          break;
        default:
          truncate = false;
          break;
      }
      return truncate;
    },
    overlayContainerDefaultHeight() {
      return 75;
    },
    overlayTopLeftContainerStyle() {
      let height;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          height = this.overlayContainerDefaultHeight * 0.35 + "vh";
          break;
        default:
          height = this.overlayContainerDefaultHeight + "vh";
          break;
      }
      return `height: ${height}`;
    },
    overlayBottomRightContainerStyle() {
      let height;

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          height = this.overlayContainerDefaultHeight * 0.4 + "vh";
          break;
        default:
          height = this.overlayContainerDefaultHeight * 0.8 + "vh";
          break;
      }
      return `height: ${height}`;
    },
    isCustomDomain() {
      return process.env.VUE_APP_DOMAIN === "custom";
    },
  },
  created() {
    this.setUserIdAndStoreId();
  },
  methods: {
    getCategoryName(name) {
      if (name === "ZZ") {
        return "Others";
      }
      return name;
    },
    setUserIdAndStoreId() {
      const userId = this.$route.params.user;
      const storeId = this.$route.params.store;
      let alias = this.$route.params.alias || userId;
      if (this.isCustomDomain) {
        alias = window.location.hostname;
      }
      this.userId = userId;
      this.storeId = storeId;

      if (alias && alias in this.aliases) {
        const params = this.aliases[alias];
        this.alias = alias;
        this.userId = params.user;
        this.storeId = params.store;
      }
    },
    getRouterPrefix() {
      if (this.isCustomDomain) {
        return "custom-domain";
      }
      return "alias";
    },
    getLinkName(name) {
      if (this.alias) {
        return `${this.getRouterPrefix()}-${name}`;
      }
      return name;
    },
    getPageLink(title) {
      const params = this.getLinkParams;
      params["pageName"] = title;
      return {
        name: this.getLinkName("page"),
        params: params,
      };
    },
    categoryHomeLink(category) {
      let link;
      if (this.alias) {
        link = {
          name: `${this.getRouterPrefix()}-category`,
          params: {
            alias: this.alias,
            category: category,
          },
        };
      } else {
        link = {
          name: "category",
          params: {
            user: this.userId,
            store: this.storeId,
            category: category,
          },
        };
      }
      return link;
    },
    showProductOverlayIfFoundAnchor(sku) {
      if (this.$route.hash && this.$route.hash.slice(1) === sku) {
        this.overlay = true;
      }
    },
    showProductOverlay(sku) {
      this.$router.push({ hash: "#" + sku });
    },
    getProductImageUrl(sku) {
      const product = this.getProductLookup[sku];
      let src = this.defaultImageSrc;
      if (!product) {
        return src;
      }
      if (!product.image_urls) {
        return src;
      }
      if (product.image_urls.length > 0) {
        src = product.image_urls[0];
      }
      return src;
    },
    getProductDetails(sku) {
      return this.getProductLookup[sku];
    },
    isProductOutOfStock(sku) {
      const product = this.getProductLookup[sku];
      if (!product) {
        return true;
      }
      if (product.max_qty_int <= 0) {
        return true;
      }
      return product.max_qty_int === (this.getCart[sku] || 0);
    },
    getOrDefaultProductImageUrls(product) {
      return product.image_urls || [this.defaultImageSrc];
    },
    getMarkdownFromText(text) {
      if (!text) {
        return "";
      }

      const renderer = md({
        html: false,
        linkify: true,
        typographer: true,
      }).use(iterator, "url_new_win", "link_open", function (tokens, idx) {
        var aIndex = tokens[idx].attrIndex("target");

        if (aIndex < 0) {
          tokens[idx].attrPush(["target", "_blank"]);
        } else {
          tokens[idx].attrs[aIndex][1] = "_blank";
        }
      });

      const html = renderer.render(text);
      return html;
    },
    getDescriptionHTML(product) {
      return this.getMarkdownFromText(product.description);
    },
    getProductWithOptions(optionName) {
      return this.getArgs["catalog-options"][optionName];
    },
    isProductWithOptions(sku) {
      const product = this.getProductDetails(sku);
      return product["option:name"] !== null;
    },
    sendRemoveFromCartEvent(sku) {
      this.$gtag.event("remove_from_cart", {
        item: [this.getGTagItem(sku)],
        value: this.getProductDetails(sku).price,
      });
    },
    sendAddToCartEvent(sku) {
      this.$gtag.event("add_to_cart", {
        item: [this.getGTagItem(sku)],
        value: this.getProductDetails(sku).price,
      });
    },
    // TODO: consolidate duplicated implementation
    getGTagItem(sku, trackView) {
      const product = this.getProductDetails(sku);
      let quantity;
      if (!trackView) {
        quantity = (this.getProducts[sku] || {}).order_qty;
      }

      return {
        id: product.name,
        name: product.nickname,
        category: product.category,
        price: product.price,
        quantity: quantity,
      };
    },
    trackViewProduct(sku) {
      this.$gtag.event("view_item", {
        item: [this.getGTagItem(sku, true)],
        value: this.getProductDetails(sku).price,
      });
    },
    trackClickAd(sku) {
      this.$gtag.event("amazon_ad", {
        event_category: "engagement",
        event_label: sku,
      });
    },
    getFormattedPrice(price) {
      const currencyCode = this.getArgs["currency-code"];
      const currency = this.customCurrencies[currencyCode];
      let formattedPrice = this.$n(price, "currency");
      if (currency) {
        formattedPrice =
          formattedPrice.slice(currencyCode.length + 1) + currency.symbol;
      }
      return formattedPrice;
    },
  },
};
