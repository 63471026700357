<template>
  <v-container>
    <v-col align="center">
      <v-col v-for="row in 3" :key="row" class="py-0">
        <Square
          v-for="i in 3"
          :key="indexByRow(i, row)"
          :value="squares[indexByRow(i, row)]"
          :disabled="!!winner"
          :winner="!!winner && winner.includes(indexByRow(i, row))"
          @click="click(i, row)"
        />
      </v-col>
    </v-col>
  </v-container>
</template>

<script>
import Square from "@/components/game/TikTokToe/Square.vue";

export default {
  name: "Board",
  props: {
    squares: Array,
    winner: Array,
  },
  components: { Square },
  methods: {
    indexByRow(index, row, max = 3) {
      return row * max + index - (max + 1);
    },
    click(index, row) {
      this.$emit("click", this.indexByRow(index, row));
    },
  },
};
</script>
