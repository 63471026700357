<template>
  <v-container fill-height>
    <v-row align="center" justify="space-between">
      <v-col class="text-center">
        <v-card elevation="0">
          <v-col class="py-5">
            <span class="text-center text-h5">
              {{ content[status].text }}
            </span>
          </v-col>
          <v-img contain max-height="500" :src="content[status].img" />
          <v-col class="px-0">
            <v-btn
              tile
              block
              large
              dark
              v-if="content[status].goBack"
              class="btn-glow"
              @click="goHome()"
            >
              <span class="text-h6 custom-font abel"> Go Back to Earth </span>
            </v-btn>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.btn-glow {
  background: linear-gradient(273deg, #be93c5, #7bc6cc);
  background-size: 400% 400%;

  -webkit-animation: glowAnimation 3s ease infinite;
  -moz-animation: glowAnimation 3s ease infinite;
  animation: glowAnimation 3s ease infinite;
}

@-webkit-keyframes glowAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes glowAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes glowAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "LoadingStore",
  data() {
    return {
      computedStatus: null,
      content: {
        fetching: {
          text: "Teleporting from Outerspace",
          img: "https://media.giphy.com/media/l2JhN2iU8GePMSa9W/giphy.gif",
        },
        failed: {
          text: "Uh Oh. We cannot find what you are looking for.",
          img: "https://media.giphy.com/media/S3bZdGN7y2MQyNgPF1/giphy.gif",
          goBack: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters("chopin", ["getFetchError"]),
    status() {
      return this.computedStatus ? this.computedStatus : this.getStatus();
    },
  },
  methods: {
    ...mapActions("chopin", ["resetFetchStoreAction"]),
    getStatus() {
      if (this.computedStatus) {
        return this.computedStatus;
      }
      let status;
      if (this.getFetchError === null) {
        status = "fetching";
      } else if (this.$route.name !== "intro") {
        setInterval(() => this.goHome(), 5000);
        status = "failed";
      }
      this.computedStatus = status;
      return status;
    },
    goHome() {
      this.resetFetchStoreAction();
      this.$router.push({ name: "intro" });
    },
  },
};
</script>
