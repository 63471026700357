<template>
  <v-container fluid class="px-0">
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-tabs
          next-icon="mdi-arrow-right-bold-box-outline"
          color="black"
          v-model="tab"
        >
          <v-tab :key="i" v-for="(t, i) in tabs" class="px-0">
            <span class="text-capitalize">{{ t.key }}</span>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12">
        <v-tabs-items v-model="tab">
          <v-tab-item :key="i" v-for="(t, i) in tabs">
            <component
              :is="t.component"
              :key="i"
              :user="user"
              :promptFn="promptFn"
              :errorFn="errorFn"
              :showAlertOnlyErrorFn="showAlertOnlyErrorFn"
            ></component>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.v-slide-group__prev.v-slide-group__prev--disabled {
  display: none !important;
}
</style>

<script>
import { mapGetters } from "vuex";

import StripeForm from "@/components/checkout/payments/StripeForm.vue";
import SendMoney from "@/components/checkout/payments/SendMoney.vue";
import PayCash from "@/components/checkout/payments/PayCash.vue";
import SubmitOrder from "@/components/checkout/payments/SubmitOrder.vue";

export default {
  name: "SelectPayment",
  components: { StripeForm, SendMoney, PayCash, SubmitOrder },
  props: ["user", "promptFn", "errorFn", "showAlertOnlyErrorFn"],
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    ...mapGetters("chopin", ["getArgs"]),
    tabs() {
      return [
        {
          key: "credit card",
          component: StripeForm,
          show: this.getArgs["payments-conf"]["show-credit-card"],
        },
        // {
        //   key: "paypal",
        //   component: PaypalForm,
        //   show: this.getArgs['payments-conf']['???'],
        // },
        {
          key: "send money",
          component: SendMoney,
          show: this.getArgs["payments-conf"]["show-send-money"],
        },
        {
          key: "pay cash",
          component: PayCash,
          show: this.getArgs["payments-conf"]["show-submit-order"],
        },
        {
          key: "submit order",
          component: SubmitOrder,
          show: this.getArgs["payments-conf"]["show-default-submit-order"],
        },
      ].filter((t) => t.show);
    },
  },
};
</script>
