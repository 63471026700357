<template>
  <v-container fluid v-if="argsFetched" class="full-screen">
    <v-container class="pt-15 pb-0">
      <v-row class="mx-3 font-weight-bold">
        <v-col cols="12" class="px-0 mx-0 mb-0">
          <v-breadcrumbs
            :items="items"
            class="px-0 pb-0 mx-0 mb-0 text-caption"
          >
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
    </v-container>
    <Products :products="getProduocts" :end="true" :showPage="true" />
    <ProductOverlay />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { ChopinMixin } from "@/lib/ChopinMixin.js";
import Products from "@/components/product/Products";
import ProductOverlay from "@/components/product/ProductOverlay";

export default {
  name: "ShowAllHome",
  components: { Products, ProductOverlay },
  mixins: [ChopinMixin],
  computed: {
    ...mapGetters("chopin", ["argsFetched", "getArgs"]),
    items() {
      return [
        {
          text: "Home",
          exact: true,
          to: this.storeHomeLink,
          // href: "#"
        },
        {
          text: "All Products",
        },
      ];
    },
    getProduocts() {
      let products = [];
      Object.entries(this.getArgs["catalog-by-category"]).forEach((x) => {
        products = products.concat(x[1]);
      });
      return products;
    },
  },
};
</script>
