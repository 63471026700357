<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-btn
          block
          tile
          height="50px"
          :color="method.color"
          outlined
          style="text-decoration: none !important;"
          @click="doPayNowOffline(method, promptFn, errorFn)"
        >
          {{ method.display }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { PaymentsMixin } from "@/lib/PaymentsMixin.js";

export default {
  name: "SubmitOrder",
  props: ["user", "promptFn", "errorFn"],
  mixins: [PaymentsMixin],
  computed: {
    method() {
      return this.getPaymentMethodConf("Manual");
    },
  },
};
</script>
