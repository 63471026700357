<template>
  <v-btn
    bottom
    @click="addToCartAndNotification(product)"
    :disabled="isProductOutOfStock(product.name)"
    tile
    block
    absolute
    v-bind:class="{
      'add-to-cart': !isProductOutOfStock(product.name),
      oos: isProductOutOfStock(product.name),
    }"
    style="bottom: 0"
  >
    <span
      v-if="!isProductOutOfStock(product.name)"
      class="white--text text-title"
    >
      Add To Cart
    </span>
    <span class="black--text" v-else>
      {{ getArgs.store.out_of_stock_text || "Out of stock" }}
    </span>
    <v-snackbar
      light
      bottom
      text
      :color="snackbar.color"
      v-model="snackbar.added"
      :timeout="snackbar.timeout"
    >
      <v-col class="pa-0 text-center text-uppercase">
        {{ snackbar.text }}
      </v-col>
    </v-snackbar>
  </v-btn>
</template>

<style lang="scss">
.add-to-cart {
  height: 54px !important;
  background: linear-gradient(90deg, #fc5c7d, #6a82fb);
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg.oos {
  height: 54px !important;
  background: lightgrey !important;
}
</style>

<script>
import { mapActions } from "vuex";
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";

export default {
  name: "ProductDetail",
  props: ["product"],
  mixins: [ChopinProductMixin],
  data() {
    return {
      snackbar: {
        color: "success",
        added: false,
        timeout: 1000,
        text: "ADDED",
      },
    };
  },
  methods: {
    ...mapActions("cart", ["addToCart"]),
    // NOTE: consolidate into one
    addToCartAndNotification(p) {
      this.snackbar.added = true;
      this.snackbar.text = "ADDED";
      this.snackbar.color = "success";
      if (
        this.getCart[p.name] &&
        this.getCart[p.name].quantity === p.max_qty_int
      ) {
        this.snackbar.text = `Limit ${p.max_qty_int} Per Customer`;
        this.snackbar.color = "error";
      }
      this.addToCart(p);
      this.sendAddToCartEvent(p.name);
    },
  },
};
</script>
