<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-5">
      <v-btn
        v-for="(social, i) in socialsAppended"
        :key="i"
        :color="social.color"
        fab
        icon
        small
        :href="social.href"
        target="_blank"
      >
        <v-icon>{{ social.icon }}</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Contacts",
  props: ["socials"],
  data() {
    return {
      iconLookup: {
        email: {
          icon: "mdi-email-outline",
          color: "black",
          hrefFormat: (value) => `mailto:${value}`,
        },
        facebook: {
          icon: "mdi-facebook",
          color: "blue",
          hrefFormat: (value) => value,
        },
        instagram: {
          icon: "mdi-instagram",
          color: "red",
          hrefFormat: (value) => `https://instagram.com/${value}`,
        },
        line: {
          icon: "fab fa-line",
          color: "green",
          hrefFormat: (value) => value,
        },
        phone: {
          icon: "mdi-phone",
          color: "darkgrey",
          hrefFormat: (value) => `tel:${value}`,
        },
        whatsapp: {
          icon: "mdi-whatsapp",
          color: "green",
          hrefFormat: (value) => `https://api.whatsapp.com/send?phone=${value}`,
        },
        twitter: {
          icon: "mdi-twitter",
          color: "#1DA1F2",
          hrefFormat: (value) => `https://twitter.com/${value}`,
        },
      },
    };
  },
  computed: {
    socialsAppended() {
      return Object.entries(this.socials).map((k) => {
        const [name, value] = k;
        const lookup = this.iconLookup[name];
        return {
          icon: lookup.icon,
          color: lookup.color,
          value: value,
          href: lookup.hrefFormat(value),
        };
      });
    },
  },
};
</script>
