<template>
  <v-btn
    :class="[value, { winner: winner }]"
    :disabled="disabled"
    tile
    outlined
    fab
    @click="click"
  >
    {{ value }}
  </v-btn>
</template>

<script>
export default {
  name: "Square",
  props: {
    value: String,
    winner: Boolean,
    disabled: Boolean,
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped></style>
