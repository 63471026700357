<template>
  <v-card flat tile :height="getOrDefaultHeight">
    <v-carousel
      class="py-0"
      show-arrows-on-hover
      hide-delimiter-background
      width="100%"
      height="100%"
    >
      <v-carousel-item v-for="(image, i) in atLeastTwoSlide" :key="i">
        <v-card
          flat
          tile
          height="100%"
          width="100%"
          :img="image"
          :color="getOrDefaultColor"
        >
          <v-container fill-height>
            <v-row
              class="text-center black--text text-h5 mx-0"
              align="center"
              justify="center"
            >
              <v-col
                class="px-3 col-auto"
                style="height: 100%"
                v-bind:class="{ darkBg: !lightBg }"
              >
                <template v-if="i === 0">
                  <v-avatar size="200px">
                    <v-img :src="logo"></v-img>
                  </v-avatar>
                </template>
                <template v-if="i === 1">
                  <span
                    class="description-markdown-html hide-ul-bullet"
                    v-html="getStoreDescriptionHTML"
                  ></span>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<style lang="scss">
.full-screen {
  width: 100%;
  height: 100%;
}
.darkBg {
  color: white;
}

.description-markdown-html.hide-ul-bullet ul {
  list-style: none;
}
.description-markdown-html table {
  font-size: 90%;
}
</style>

<script>
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";

export default {
  name: "Hero",
  props: [
    "height",
    "color",
    "title",
    "images",
    "logo",
    "description",
    "name",
    "lightBg",
  ],
  mixins: [ChopinProductMixin],
  data() {
    return {
      descriptionHTML: "",
    };
  },
  computed: {
    getOrDefaultHeight() {
      // return "100%";
      return this.height ? `${this.height}%` : "100%";
    },
    getOrDefaultColor() {
      return this.color ? this.color : "secondary";
    },
    getStoreDescriptionHTML() {
      return this.getMarkdownFromText(this.description);
    },
    atLeastTwoSlide() {
      let images = this.images;
      if (this.getStoreDescriptionHTML && this.images.length < 2) {
        images = [this.images[0], this.images[0]];
      }
      return images;
    },
  },
};
</script>
