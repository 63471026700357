<template>
  <v-container fluid v-if="argsFetched" class="full-screen">
    <v-container class="pt-15 pb-0">
      <v-row class="mx-3 font-weight-bold">
        <v-col cols="12" class="px-0 mx-0 mb-0">
          <v-breadcrumbs
            :items="items"
            class="px-0 pb-0 mx-0 mb-0 text-caption"
          >
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
    </v-container>
    <template>
      <v-container class="pt-0 mt-0">
        <v-row class="font-weight-light my-5 mx-3">
          <v-col class="px-0 mx-0 pb-0 mb-0">
            <span class="text-h5 d-block">
              {{ pageName }}
            </span>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pt-0 mt-0">
        <v-row class="pb-15 my-5 mx-0">
          <v-col class="markdown">
            <span v-html="getMarkdownFromText(markdown)"></span>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-container>
</template>

<style>
.markdown img {
  max-width: 100%;
}
</style>

<script>
import { mapGetters } from "vuex";
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";

export default {
  name: "PageMain",
  mixins: [ChopinProductMixin],
  updated() {
    const checkIfPageExists = () => {
      if (this.argsFetched && this.page.length === 0) {
        this.$router.push(this.storeHomeLink);
      }
    };
    checkIfPageExists();
  },
  computed: {
    ...mapGetters("chopin", ["getArgs", "getStore", "argsFetched"]),
    pageName() {
      return this.$route.params.pageName;
    },
    items() {
      return [
        {
          text: "Home",
          exact: true,
          to: this.storeHomeLink,
          // href: "#"
        },
        {
          text: this.pageName,
        },
      ];
    },
    page() {
      return this.getStore.pages.filter((x) => x.title === this.pageName);
    },
    markdown() {
      return this.page.length > 0 ? this.page[0].content : "";
    },
  },
};
</script>
