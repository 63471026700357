<template>
  <v-container class="pt-15" v-if="argsFetched">
    <v-row align="end">
      <v-col style="height: 100%">
        <v-breadcrumbs :items="items" class="px-0 pb-0 mx-0 mb-0 text-caption">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0 pb-15">
        <CategoryCollage
          :catalogByCategory="getArgs['catalog-by-category']"
          layout="enlarge"
          n="-1"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CategoryCollage from "@/components/category/CategoryCollage";
import { ChopinMixin } from "@/lib/ChopinMixin.js";

export default {
  name: "CategoryCollageHome",
  components: { CategoryCollage },
  mixins: [ChopinMixin],
  computed: {
    items() {
      return [
        {
          text: "Home",
          exact: true,
          to: this.storeHomeLink,
          // href: "#"
        },
        {
          text: "Categories",
        },
      ];
    },
  },
};
</script>
