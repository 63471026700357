<template>
  <v-container fluid class="px-3 mx-0" style="height: 100%; width: 100%">
    <v-row
      class="py-5 px-0 mx-0 pt-10"
      justify="center"
      align="center"
      style="width: 100%"
    >
      <v-col cols="12" sm="6" class="product-detail">
        <v-carousel
          show-arrows-on-hover
          hide-delimiter-background
          :style="overlayTopLeftContainerStyle"
        >
          <v-carousel-item
            v-for="(src, i) in getOrDefaultProductImageUrls(
              getActiveOptionProduct()
            )"
            style="height: 100%"
            :key="i"
          >
            <v-img :src="src" contain style="height: 100%" />
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        align-self="start"
        class="px-3 pb-5"
        style="height: 100%"
      >
        <v-col class="pa-0">
          <p
            class="text-h5 mb-1"
            v-bind:class="{ 'text-truncate': overlayProductNameTruncate }"
          >
            {{ getActiveOptionProduct().nickname }}
          </p>

          <p class="text-title mb-1">
            {{ getFormattedPrice(getActiveOptionProduct().price) }}
          </p>
        </v-col>

        <v-col
          class="overflow-y-auto"
          :style="overlayBottomRightContainerStyle"
        >
          <v-row class="pa-0" v-if="display.flavor.options.length > 0">
            <v-select
              v-model="toggle.flavor.index"
              :items="converArrayToArrayObjects(display.flavor.options)"
              item-text="name"
              item-value="value"
              @click="flavorSelectOpen"
              @change="flavorSelectClick"
              @blur="flavorSelectClickOutside"
            >
            </v-select>
          </v-row>
          <v-row class="pa-0" v-if="display.color.options.length > 0">
            <v-btn-toggle
              v-model="toggle.color.index"
              active-class="color-active"
              group
              mandatory
              borderless
              dense
              @change="setAllOptions()"
              style="width: 40px"
            >
              <v-btn
                v-for="(c, i) in display.color.options"
                class="rounded-circle mx-1"
                height="48px"
                width="35px"
                :key="i"
              >
                <v-sheet
                  class="rounded-circle"
                  :color="c"
                  height="35px"
                  width="35px"
                ></v-sheet>
              </v-btn>
            </v-btn-toggle>
          </v-row>
          <v-row class="pa-0" v-if="display.size.options.length > 0">
            <v-btn-toggle
              v-model="toggle.size.index"
              active-class="size-active"
              group
              mandatory
              borderless
              dense
              @change="setAllOptions()"
            >
              <v-col class="pa-0">
                <v-btn
                  v-for="(s, i) in display.size.options"
                  class="mx-1 my-1"
                  height="25"
                  min-width="25"
                  outlined
                  color="success"
                  :key="i"
                >
                  {{ s }}
                </v-btn>
              </v-col>
            </v-btn-toggle>
          </v-row>
          <v-row class="pt-3">
            <span v-html="getDescriptionHTML(getActiveOptionProduct())"></span>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss"></style>

<script>
import { ChopinProductMixin } from "@/lib/ChopinProductMixin.js";

export default {
  name: "ProductWithOptionsDetail",
  props: ["product", "flavorSelect"],
  mixins: [ChopinProductMixin],
  data() {
    return {
      toggle: {
        flavor: {
          index: 0,
        },
        color: {
          index: 0,
        },
        size: {
          index: 0,
        },
      },
      display: {
        flavor: {
          name: null,
          options: [],
        },
        color: {
          name: null,
          options: [],
        },
        size: {
          name: null,
          options: [],
        },
      },
    };
  },
  computed: {
    flavorSelectMutable() {
      return this.flavorSelect;
    },
    getOptionName() {
      return this.product.name;
    },
    getOptionDetail() {
      return this.getProductWithOptions(this.getOptionName);
    },
    getConditionalMapping() {
      return this.getOptionDetail["conditional_options"];
    },
  },
  mounted() {
    this.setAllOptions();
  },
  watch: {
    toggle: {
      handler(update, current) {
        const attributes = ["color", "flavor", "size"];
        attributes.forEach((x) => {
          if (current[x].index !== update[x].index) {
            this.setAllOptions(x);
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    flavorSelectOpen() {
      // NOTE: set select to open when first click on select
      this.flavorSelectMutable.open = true;
    },
    flavorSelectClick() {
      // NOTE: keep select open if click on option and let click outside on overlay to set it to false
      this.flavorSelectMutable.open = true;
      this.setAllOptions();
    },
    flavorSelectClickOutside() {
      // NOTE: when click outside, set select to close so it won't be detected as click outside on overlay
      this.flavorSelectMutable.open = false;
    },
    converArrayToArrayObjects(array) {
      const arrayObjects = array.map((x, i) => {
        return { name: x, value: i };
      });
      return arrayObjects;
    },
    getConditionalMappingOrOptions(optionType) {
      let options;
      options = Object.keys(this.getConditionalMapping[optionType] || {});
      if (options.length > 0) {
        return options;
      }
      options = this.getOptionDetail["options"][optionType];
      return options;
    },
    setAllOptions(pinned) {
      if (pinned !== "flavor") {
        const flavors = this.getFlavorOptions();
        if (flavors) {
          this.display.flavor.name = flavors[this.toggle.flavor.index];
          this.display.flavor.options = flavors;
        }
      }
      if (pinned !== "color") {
        const colors = this.getColorOptions();
        if (colors) {
          this.display.color.name = colors[this.toggle.color.index];
          this.display.color.options = colors;
        }
      }
      if (pinned !== "size") {
        const sizes = this.getSizeOptions();
        if (sizes) {
          this.display.size.name = sizes[this.toggle.size.index];
          this.display.size.options = sizes;
        }
      }
    },
    getActiveOptionProduct() {
      const optionId = [
        this.display.flavor.name,
        this.display.color.name,
        this.display.size.name,
      ]
        .filter((x) => x)
        .join(";");
      const selectedProduct = this.getOptionDetail.option_map[optionId];
      if (this.getOptionDetail.option_map[optionId]) {
        this.$emit("onUpdateMultiOptionToggle", selectedProduct);

        return selectedProduct;
      }
      return {};
    },
    getFlavorOptions() {
      let flavors;
      flavors = Object.keys(this.getConditionalMapping.Flavor || {});
      if (flavors.length > 0) {
        return flavors;
      } else {
        flavors = this.getConditionalMappingOrOptions("Flavor");
      }
      return flavors;
    },
    getColorOptions() {
      let colors = Object.keys(this.getConditionalMapping.Color || {});
      const flavor = this.display.flavor.name;
      if (colors.length > 0) {
        return colors;
      } else if (flavor && this.getConditionalMapping.Flavor) {
        colors = this.getConditionalMapping.Flavor[flavor].Color;
      } else {
        colors = this.getConditionalMappingOrOptions("Color");
      }
      return colors;
    },
    getSizeOptions() {
      let sizes = Object.keys(this.getConditionalMapping.Size || {});
      const flavor = this.display.flavor.name;
      const color = this.display.color.name;
      if (sizes.length > 0) {
        return sizes;
      } else if (flavor && this.getConditionalMapping.Flavor) {
        sizes = this.getConditionalMapping.Flavor[flavor].Size;
      } else if (color && this.getConditionalMapping.Color) {
        sizes = this.getConditionalMapping.Color[color].Size;
      } else {
        sizes = this.getConditionalMappingOrOptions("Size");
      }
      return sizes;
    },
  },
};
</script>
